import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion'
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { useBuyersPerformanceQuery } from '@/queries/usePurchaseOrderQuery'
import { useMemo } from 'react'
import getCssVariable from '../PriorityChart/util/getCssVariable'
import { Intervals, Priority } from '@/api/business/solicitacaoDeCompra/type'
import { Skeleton } from '@/components/ui/skeleton'
import { isAxiosError } from '@/api/business'
import { useDateIntervalContext } from '@/context/DateIntervalContextProvider'

const BuyersPerformanceContent = () => {
    const { endDate, startDate } = useDateIntervalContext()

    const {
        data = [],
        isPending,
        error,
    } = useBuyersPerformanceQuery({ endDate, startDate })

    const uniqueBuyers = useMemo(() => {
        const unique: Record<
            string,
            {
                name: string
                total: number
                priority: Record<
                    string,
                    {
                        priority: Priority
                        intervals: Record<
                            string,
                            {
                                interval: Intervals
                                total: number
                            }
                        >
                    }
                >
            }
        > = {}

        for (const row of data) {
            if (!unique[row.DS_COMPRADOR]) {
                unique[row.DS_COMPRADOR] = {
                    name: row.DS_COMPRADOR || '-',
                    total: 0,
                    priority: {},
                }
            }

            if (!unique[row.DS_COMPRADOR].priority[row.DD_URGENCIA]) {
                unique[row.DS_COMPRADOR].priority[row.DD_URGENCIA] = {
                    priority: row.DD_URGENCIA,
                    intervals: {},
                }
            }

            if (
                !unique[row.DS_COMPRADOR].priority[row.DD_URGENCIA].intervals[
                    row.INTERVALO_PRAZO
                ]
            ) {
                unique[row.DS_COMPRADOR].total += row.TOTAL
                unique[row.DS_COMPRADOR].priority[row.DD_URGENCIA].intervals[
                    row.INTERVALO_PRAZO
                ] = {
                    interval: row.INTERVALO_PRAZO,
                    total: 0,
                }
            }

            unique[row.DS_COMPRADOR].priority[row.DD_URGENCIA].intervals[
                row.INTERVALO_PRAZO
            ] = {
                ...unique[row.DS_COMPRADOR].priority[row.DD_URGENCIA].intervals[
                    row.INTERVALO_PRAZO
                ],
                total:
                    unique[row.DS_COMPRADOR].priority[row.DD_URGENCIA]
                        .intervals[row.INTERVALO_PRAZO].total + row.TOTAL,
            }
        }

        return Object.values(unique)
            .map((buyer) => ({
                ...buyer,
                priority: Object.values(buyer.priority).map((priority) => ({
                    ...priority,
                    intervals: Object.values(priority.intervals),
                })),
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
    }, [data])

    if (isPending) {
        return (
            <div className="space-y-1">
                <Skeleton className="w-full h-[24px]" />
                <Skeleton className="w-full h-[24px]" />
                <Skeleton className="w-full h-[24px]" />
                <Skeleton className="w-full h-[24px]" />
                <Skeleton className="w-full h-[24px]" />
            </div>
        )
    }

    if (error) {
        let errorMessage = 'Erro ao listar compradores'

        if (isAxiosError(error)) {
            errorMessage = error.response?.data.message || errorMessage
        }

        return (
            <div>
                <TypographyP className="text-destructive">
                    {errorMessage}
                </TypographyP>
            </div>
        )
    }

    if (data.length === 0)
        return (
            <div>
                <TypographyMuted>Não há dados disponíveis</TypographyMuted>
            </div>
        )

    return (
        <div className="space-y-4 max-h-[400px] overflow-auto">
            <Accordion type="single" collapsible>
                {uniqueBuyers.map((buyer) => (
                    <AccordionItem key={buyer.name} value={buyer.name}>
                        <AccordionTrigger className="font-normal">
                            <TypographyP>
                                <span className="font-semibold">
                                    {buyer.name}
                                </span>{' '}
                                ({buyer.total} pedidos)
                            </TypographyP>
                        </AccordionTrigger>
                        <AccordionContent className="p-4 rounded-lg bg-neutral-50 dark:bg-card">
                            <div className="grid grid-cols-[repeat(auto-fill,minmax(max-content,200px))] gap-4">
                                {buyer.priority.map((priority) => (
                                    <div key={priority.priority}>
                                        <TypographyP
                                            style={{
                                                color: `hsl(var(${getCssVariable(
                                                    priority.priority
                                                )}))`,
                                            }}
                                            className="font-bold"
                                        >
                                            {priority.priority}
                                        </TypographyP>
                                        <div className="space-y-1">
                                            {priority.intervals.map(
                                                (interval) => (
                                                    <div
                                                        key={interval.interval}
                                                    >
                                                        <TypographyP className="text-xs truncate">
                                                            {interval.interval}{' '}
                                                            -{' '}
                                                            <span className="font-semibold">
                                                                {interval.total}
                                                            </span>
                                                        </TypographyP>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </AccordionContent>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    )
}

const BuyersPerformanceSection = () => {
    return (
        <Card>
            <CardHeader>
                <CardTitle>Pedidos por comprador</CardTitle>
                <CardDescription>
                    Listagem de PCs por comprador separadas por prioridade
                </CardDescription>
            </CardHeader>
            <CardContent>
                <BuyersPerformanceContent />
            </CardContent>
        </Card>
    )
}

export default BuyersPerformanceSection
