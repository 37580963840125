import { PurchaseRequestFlat } from '@/api/business/solicitacaoDeCompra/type'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'
import Field from './components/Field'

type DetailsSectionProps = {
    purchaseRequest: PurchaseRequestFlat
}

const DetailsSection = ({ purchaseRequest }: DetailsSectionProps) => {
    return (
        <div className="px-4 pb-4 space-y-2 max-w-[460px]">
            <Field label="Produto" value={purchaseRequest.DS_PRODUTO} />
            <Field
                label="Unidade fabril"
                value={purchaseRequest.ABREVIATURA_EMPRESA}
            />
            <Field
                label="Data de emissão"
                value={format(
                    getDateFromId(purchaseRequest.DT_EMISSAO),
                    'dd/MM/yyyy'
                )}
            />
            <Field
                label="Centro de custo"
                value={purchaseRequest.CENTRO_DE_CUSTO}
            />
            <Field label="Prioridade" value={purchaseRequest.DD_URGENCIA} />
            <Field
                label="Aprovador"
                value={purchaseRequest.DD_APROVADOR || '-'}
            />
            <Field
                label="Comprador responsável"
                value={purchaseRequest.DS_NOME_COMPRADOR || '-'}
            />
            <Field
                label="Observação"
                value={purchaseRequest.DD_OBSERVACAO || '-'}
            />
        </div>
    )
}

export default DetailsSection
