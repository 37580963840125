import { TypographyLarge } from '@/components/ui/typography'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { cn } from '@/lib/utils'

type LeadTimeCardProps = {
    label: string
    value: number
    labelClassName?: string
    valueClassName?: string
}

const LeadTimeCard = ({
    label,
    value,
    labelClassName,
    valueClassName,
}: LeadTimeCardProps) => {
    return (
        <Card className="rounded-md shadow-none">
            <CardHeader className="p-3">
                <CardTitle
                    className={cn(
                        'text-sm font-normal text-muted-foreground',
                        labelClassName
                    )}
                >
                    {label}
                </CardTitle>
            </CardHeader>
            <CardContent>
                <TypographyLarge
                    className={cn(
                        'text-2xl font-semibold text-end',
                        valueClassName
                    )}
                >
                    {value} dia{value > 1 || value === 0 ? 's' : ''}
                </TypographyLarge>
            </CardContent>
        </Card>
    )
}

export default LeadTimeCard
