import { cn } from '@/lib/utils'
import { QuotationHeader } from '@/types/Quotation'
import {
    QuotationStatus,
    QuotationStatusColorScheme,
} from '@/types/QuotationStatus'
import { addHours, format } from 'date-fns'
import { Clock, User } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { Variants, motion } from 'framer-motion'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'

interface ItemProps extends QuotationHeader {}

const Item = ({
    SK_COTACAO,
    NUMERO_DA_COTACAO,
    NM_COTACAO,
    DS_COTACAO,
    EMPRESAS,
    NOME_USUARIO,
    EMAIL_USUARIO,
    STATUS,
    TOTAL_FORNECEDORES,
    TOTAL_PRODUTOS,
    TOTAL_RODADA,
    CREATED_AT,
    DT_EXPIRACAO,
}: ItemProps) => {
    const navigate = useNavigate()
    const variants: Variants = {
        initial: {
            opacity: 0,
            y: -10,
        },
        animate: {
            opacity: 1,
            y: 0,
        },
        exit: {
            opacity: 0,
            y: 10,
        },
    }

    return (
        <Card className="overflow-hidden cursor-pointer hover:bg-accent ">
            <motion.div
                // className="p-4 space-y-2 border rounded-md cursor-pointer hover:bg-neutral-100 bg-background"
                onClick={() => navigate(`/cotacoes/${SK_COTACAO}`)}
                variants={variants}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <CardHeader className="pb-3 space-y-1 border-b">
                    <CardTitle>
                        {NUMERO_DA_COTACAO}
                        {NM_COTACAO ? ` - ${NM_COTACAO}` : null}
                    </CardTitle>
                    <div>
                        {DS_COTACAO && (
                            <TypographyMuted>{DS_COTACAO}</TypographyMuted>
                        )}
                    </div>
                    <TypographyP>
                        {STATUS.map((status) => (
                            <span
                                key={status}
                                className={cn(
                                    'rounded-md text-xs whitespace-nowrap',
                                    QuotationStatusColorScheme[status]
                                )}
                            >
                                {QuotationStatus[status]}
                            </span>
                        ))}
                    </TypographyP>
                </CardHeader>
                <CardContent className="pt-3">
                    <div className="space-x-2">
                        {EMPRESAS.map((company) => {
                            return (
                                <span
                                    key={company.SK_EMPRESA}
                                    className="text-sm"
                                    style={{
                                        color: company.COR_EMPRESA,
                                    }}
                                >
                                    {company.ABREVIATURA_EMPRESA}
                                </span>
                            )
                        })}
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="px-1 text-sm rounded-md text-primary-600 bg-primary-50">
                            {TOTAL_FORNECEDORES} fornecedor
                            {TOTAL_FORNECEDORES > 1 ? 'es' : ''}
                        </span>
                        <span className="px-1 text-sm rounded-md text-primary-600 bg-primary-50">
                            {TOTAL_PRODUTOS} produto
                            {TOTAL_PRODUTOS > 1 ? 's' : ''}
                        </span>
                        <span className="px-1 text-sm rounded-md text-primary-600 bg-primary-50">
                            {TOTAL_RODADA} rodada{TOTAL_RODADA > 1 ? 's' : ''}
                        </span>
                    </div>
                    <div className="grid grid-cols-[repeat(auto-fit,minmax(220px,1fr))]">
                        <div className="flex items-start gap-2">
                            <div className="p-1">
                                <User size={16} />
                            </div>
                            <div className="overflow-hidden">
                                <p className="overflow-hidden text-sm whitespace-nowrap text-ellipsis">
                                    {NOME_USUARIO}
                                </p>
                                <p className="-mt-1 overflow-hidden text-sm text-neutral-500 whitespace-nowrap text-ellipsis">
                                    {EMAIL_USUARIO}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-start gap-4 py-1">
                            <div className="flex items-start gap-2">
                                <div className="p-1">
                                    <Clock size={16} />
                                </div>
                                <div>
                                    <p className="text-sm">Expiração</p>
                                    <p className="-mt-1 text-sm text-neutral-500">
                                        {DT_EXPIRACAO &&
                                            format(
                                                addHours(
                                                    new Date(DT_EXPIRACAO),
                                                    3
                                                ),
                                                'dd/MM/yyyy'
                                            )}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-start gap-2">
                                <div className="p-1">
                                    <Clock size={16} />
                                </div>
                                <div>
                                    <p className="text-sm">Criado em</p>
                                    <p className="-mt-1 text-sm text-neutral-500">
                                        {format(
                                            new Date(CREATED_AT),
                                            'dd/MM/yyyy'
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </motion.div>
        </Card>
    )
}

export default Item
