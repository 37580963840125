import { TypographyP } from '@/components/ui/typography'
import {
    Section,
    SectionContent,
    SectionHeader,
    SectionTitle,
    SectionValue,
} from '@/components/Section'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { useQuotationSuppliersTotals } from '@/hooks/useQuotationSuppliersTotals'

const SupplierSection = () => {
    const { suppliers } = useQuotationSuppliersTotals()

    return (
        <Section>
            <SectionHeader>
                <SectionTitle>Fornecedores</SectionTitle>
            </SectionHeader>
            <SectionContent>
                {suppliers.map((supplier) => {
                    return (
                        <div
                            key={supplier.supplierId}
                            className="p-4 space-y-2 border rounded-md bg-card"
                        >
                            <div>
                                <TypographyP className="text-base font-semibold">
                                    {supplier.supplier}
                                </TypographyP>
                            </div>
                            <SectionValue
                                label="Valor total inicial"
                                value={supplier.totalInitial}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                className="font-bold"
                                label="Valor total final"
                                value={supplier.totalFinal}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                label="Total inicial - total final"
                                value={supplier.totalDifference}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                label="Saving baseline"
                                value={supplier.baselineSaving}
                                percentage={percentageFormat(
                                    supplier.baselineSavingPercentage * 100
                                )}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                label="Saving negociação"
                                value={supplier.negotiationSaving}
                                percentage={percentageFormat(
                                    supplier.negotiationSavingPercentage * 100
                                )}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                label="Saving financeiro"
                                value={supplier.financialSaving}
                                percentage={percentageFormat(
                                    supplier.financialSavingPercentage * 100
                                )}
                                formatter={currencyFormat}
                            />
                        </div>
                    )
                })}
            </SectionContent>
        </Section>
    )
}

export default SupplierSection
