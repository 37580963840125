import { Button } from '@/components/ui/button'
import {
    ArrowLeftToLine,
    ArrowRightToLine,
    Maximize,
    Minimize,
    Stamp,
} from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import TableColumnsVisibility from '../TableColumnsVisibility'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'

const stateSelector = (state: Store) => ({
    isFullscreen: state.quotationMapSlice.state.isFullscreen,
    isSummaryOpen: state.quotationMapSlice.state.isSummaryOpen,
    onToggleFullscreen:
        state.quotationMapSlice.actions.onQuotationMapToggleFullscreen,
    onSummaryOpenToggle: state.quotationMapSlice.actions.onSummaryOpenToggle,
})

const ActionBar = () => {
    const navigate = useNavigate()

    const {
        isFullscreen,
        isSummaryOpen,
        onSummaryOpenToggle,
        onToggleFullscreen,
    } = useBaseStore(stateSelector)

    return (
        <div className="flex items-center gap-2">
            <Button variant="outline" onClick={() => navigate('aprovacao')}>
                <Stamp />
                Aprovação
            </Button>
            <TableColumnsVisibility />
            <ButtonWithTooltip
                variant="outline"
                size="icon"
                onClick={onToggleFullscreen}
                tooltipContent={isFullscreen ? 'Minimizar' : 'Maximizar'}
            >
                {isFullscreen ? <Minimize /> : <Maximize />}
            </ButtonWithTooltip>
            <ButtonWithTooltip
                variant="ghost"
                size="icon"
                onClick={onSummaryOpenToggle}
                tooltipContent={
                    isSummaryOpen ? 'Fechar resumo' : 'Abrir resumo'
                }
            >
                {isSummaryOpen ? <ArrowRightToLine /> : <ArrowLeftToLine />}
            </ButtonWithTooltip>
        </div>
    )
}

export default ActionBar
