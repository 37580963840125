import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Login from '@/pages/Login'
import NotFound from '@/pages/ErrorPages/NotFound'
import PublicLayout from '@/pages/Layouts/PublicLayout'
import ResetPassword from '@/pages/ResetPassword'
import DadosMestreAvaliacoes from '@/pages/DadosMestre/DadosMestreAvaliacoes'
import DadosMestreFornecedores from '@/pages/DadosMestre/DadosMestreFornecedores'
import DadosMestreParametrosAvaliacao from '@/pages/DadosMestre/DadosMestreParametrosAvaliacao'
import DadosMestreProdutos from '@/pages/DadosMestre/DadosMestreProdutos'
import DadosMestreCompanies from '@/pages/DadosMestre/DadosMestreCompanies'
import QuotationList from '@/pages/QuotationList'
import QuotationDetail from '@/pages/QuotationDetail'
import CotacaoCreatePurchaseRequest from '@/pages/CotacaoCreatePurchaseRequest'
import CotacaoCreatePurchaseRequestGenerate from '@/pages/CotacaoCreatePurchaseRequestGenerate'
import QuotationCreatePurchaseRequestLayout from '@/pages/Layouts/QuotationCreatePurchaseRequestLayout'
import DadosMestreCondicoesDePagamento from '@/pages/DadosMestre/DadosMestreCondicoesDePagamento'
import RequireAuth from '@/middlewares/RequireAuth'
import MainLayout from '@/pages/Layouts/MainLayout'
import SupplierMap from '@/pages/SupplierMap'
import QuotationEnd from '@/pages/QuotationEnd'
import QuotationDetailLayout from '@/pages/Layouts/QuotationDetailLayout'
import QuotationApproval from '@/pages/QuotationApproval'
import PurchaseRequestDashboard from '@/pages/PurchaseRequestPage/components/PurchaseRequestDashboard'
import PurchaseRequestAnalytics from './pages/PurchaseRequestPage/components/PurchaseRequestAnalytics'
import Invoices from './pages/Invoices'
import PurchaseOrderAnalytics from '@/pages/PurchaseOrderPage/components/PurchaseOrderAnalytics'
import PurchaseOrderDashboard from './pages/PurchaseOrderPage/components/PurchaseOrderDashboard'

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PublicLayout />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/recuperar-senha"
                        element={<ResetPassword />}
                    />
                </Route>
                <Route element={<MainLayout />}>
                    <Route
                        path="/"
                        element={<QuotationCreatePurchaseRequestLayout />}
                    >
                        <Route
                            index
                            element={
                                <RequireAuth isAllowed>
                                    <CotacaoCreatePurchaseRequest />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="gerar"
                            element={
                                <RequireAuth isAllowed>
                                    <CotacaoCreatePurchaseRequestGenerate />
                                </RequireAuth>
                            }
                        />
                        <Route path="pedidos-de-compras">
                            <Route
                                index
                                element={<Navigate to="analitico" />}
                            />
                            <Route
                                path="analitico"
                                element={
                                    <RequireAuth isAllowed>
                                        <PurchaseOrderAnalytics />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="gerencial"
                                element={
                                    <RequireAuth isAllowed>
                                        <PurchaseOrderDashboard />
                                    </RequireAuth>
                                }
                            />
                        </Route>
                        <Route path="solicitacoes-de-compras">
                            <Route
                                index
                                element={<Navigate to="analitico" />}
                            />
                            <Route
                                path="analitico"
                                element={
                                    <RequireAuth isAllowed>
                                        <PurchaseRequestAnalytics />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="gerencial"
                                element={
                                    <RequireAuth isAllowed>
                                        <PurchaseRequestDashboard />
                                    </RequireAuth>
                                }
                            />
                        </Route>
                        <Route path="cotacoes">
                            <Route
                                element={
                                    <RequireAuth isAllowed>
                                        <QuotationList />
                                    </RequireAuth>
                                }
                                index
                            />
                            <Route path="criar">
                                <Route
                                    path="solicitacao-de-compras"
                                    element={
                                        <QuotationCreatePurchaseRequestLayout />
                                    }
                                >
                                    <Route
                                        index
                                        element={
                                            <RequireAuth isAllowed>
                                                <CotacaoCreatePurchaseRequest />
                                            </RequireAuth>
                                        }
                                    />
                                    <Route
                                        path="gerar"
                                        element={
                                            <RequireAuth isAllowed>
                                                <CotacaoCreatePurchaseRequestGenerate />
                                            </RequireAuth>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route
                                path=":id"
                                element={
                                    <RequireAuth isAllowed>
                                        <QuotationDetailLayout />
                                    </RequireAuth>
                                }
                            >
                                <Route
                                    index
                                    element={
                                        <RequireAuth isAllowed>
                                            <QuotationDetail />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="aprovacao"
                                    element={
                                        <RequireAuth isAllowed>
                                            <QuotationApproval />
                                        </RequireAuth>
                                    }
                                />
                                <Route path="mapa">
                                    <Route
                                        index
                                        element={<Navigate to={'..'} />}
                                    />
                                    <Route path="fornecedor">
                                        <Route
                                            index
                                            element={<Navigate to={'..'} />}
                                        />
                                        <Route
                                            path=":supplierId"
                                            element={
                                                <RequireAuth isAllowed>
                                                    <SupplierMap />
                                                </RequireAuth>
                                            }
                                        />
                                    </Route>
                                    <Route
                                        path="finalizar-cotacao"
                                        element={
                                            <RequireAuth isAllowed>
                                                <QuotationEnd />
                                            </RequireAuth>
                                        }
                                    />
                                </Route>
                            </Route>
                        </Route>
                        <Route
                            path="notas-fiscais"
                            element={
                                <RequireAuth isAllowed>
                                    <Invoices />
                                </RequireAuth>
                            }
                        />
                        <Route path="dados-mestre">
                            <Route
                                index
                                element={
                                    <RequireAuth isAllowed>
                                        <Navigate to="fornecedores" />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="avaliacoes"
                                element={
                                    <RequireAuth isAllowed={false}>
                                        <DadosMestreAvaliacoes />
                                    </RequireAuth>
                                }
                            />

                            <Route
                                path="parametros-avaliacao"
                                element={
                                    <RequireAuth isAllowed>
                                        <DadosMestreParametrosAvaliacao />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="fornecedores"
                                element={
                                    <RequireAuth isAllowed>
                                        <DadosMestreFornecedores />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="produtos"
                                element={
                                    <RequireAuth isAllowed>
                                        <DadosMestreProdutos />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="empresas"
                                element={
                                    <RequireAuth isAllowed>
                                        <DadosMestreCompanies />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="condicoes-de-pagamento"
                                element={
                                    <RequireAuth isAllowed>
                                        <DadosMestreCondicoesDePagamento />
                                    </RequireAuth>
                                }
                            />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router
