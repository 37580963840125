import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { endOfMonth, isValid, startOfMonth } from 'date-fns'
import { setItem } from '@/utils/storage'

type DateIntervalContextState = {
    startDate: Date
    endDate: Date
    setDate: (date: Date, dateType: 'startDate' | 'endDate') => void
}

type DateIntervalContextProviderProps = {
    children: React.ReactNode
    storageKey: string
}

const getInitialState = (
    key: string
): { startDate: Date; endDate: Date } | null => {
    try {
        const value = localStorage.getItem(key)
        if (!value) return null

        const json = JSON.parse(value)

        const startDate = new Date(json.startDate)
        const endDate = new Date(json.endDate)

        if (isValid(startDate) && isValid(endDate))
            return {
                endDate,
                startDate,
            }

        return null
    } catch (error) {
        return null
    }
}

const DateIntervalContext = createContext<DateIntervalContextState>({
    endDate: new Date(),
    startDate: new Date(),
    setDate: () => null,
})

const DateIntervalContextProvider = ({
    children,
    storageKey,
}: DateIntervalContextProviderProps) => {
    const initialState = useMemo(
        () =>
            getInitialState(storageKey) || {
                startDate: startOfMonth(new Date()),
                endDate: endOfMonth(new Date()),
            },
        [storageKey]
    )

    const [{ startDate, endDate }, setDate] = useState(initialState)

    const onDateChange = (date: Date, dateType: 'startDate' | 'endDate') => {
        const dateInterval = {
            startDate: dateType === 'startDate' ? date : startDate,
            endDate: dateType === 'endDate' ? date : endDate,
        }

        setItem(localStorage, storageKey, JSON.stringify({ ...dateInterval }))

        setDate((prev) => ({
            ...prev,
            [dateType]: date,
        }))
    }

    useEffect(() => {
        setDate(initialState)
    }, [initialState])

    return (
        <DateIntervalContext.Provider
            value={{
                endDate,
                startDate,
                setDate: onDateChange,
            }}
        >
            {children}
        </DateIntervalContext.Provider>
    )
}

export const useDateIntervalContext = () => {
    const context = useContext(DateIntervalContext)

    if (!context)
        throw Error(
            'useDateIntervalContext must be used within a DateIntervalContextProvider'
        )

    return context
}

export default DateIntervalContextProvider
