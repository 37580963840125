import { Bar, BarChart, LabelList, XAxis, YAxis, CartesianGrid } from 'recharts'
import { differenceInDays } from 'date-fns'
import { getDateFromId } from '@/utils/date'
import {
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
} from '@/components/ui/chart'
import { TypographyP } from '@/components/ui/typography'
import {
    NameType,
    ValueType,
    Formatter,
} from 'recharts/types/component/DefaultTooltipContent'
import { dateFormater, getEndDate } from './utils/leadTimeUtil'
import StartDateLabel from './components/StartDateLabel'
import { cn } from '@/lib/utils'

type LeadTimeChartProps = {
    createdAt: number
    approvedAt: number | null
    orderedAt: number | null
    deliveredAt: number | null
    className?: string
}

export type LeadtimeChartData = {
    task: string
    startDate: number
    endDate: number
    duration: number
}

const labelMapper = {
    startDate: 'Data inicio',
    endDate: 'Data fim',
    duration: 'Intervalo',
} as const

const labelFormatter: Formatter<ValueType, NameType> = (
    ...[, name, item, _]
) => {
    let itemValue = item.payload[name]
    const startDate = item.payload.startDate
    const endDate = item.payload.endDate

    if (name === 'duration') {
        if (
            startDate &&
            endDate &&
            startDate > 0 &&
            endDate > 0 &&
            endDate > startDate
        ) {
            const diff = differenceInDays(endDate, startDate)
            itemValue = diff
        }
    }

    const Item = {
        duration: () => {
            if (startDate > endDate || startDate === endDate) return '-'

            return itemValue > 0
                ? `${itemValue} dia${itemValue > 1 ? 's' : ''}`
                : '-'
        },
        endDate: () => {
            return itemValue > 0 ? dateFormater(itemValue) : '-'
        },
    }

    return (
        <div className="flex justify-between w-full gap-4">
            <TypographyP className="text-xs text-muted-foreground">
                {labelMapper[name]}:{' '}
            </TypographyP>
            <TypographyP className="text-xs">
                {Item[name] ? Item[name]() : dateFormater(itemValue)}
            </TypographyP>
        </div>
    )
}

const LeadTimeChart = ({
    approvedAt,
    createdAt,
    deliveredAt,
    orderedAt,
    className,
}: LeadTimeChartProps) => {
    const createdAtDate = getDateFromId(createdAt).getTime()
    const approvedAtDate = approvedAt ? getDateFromId(approvedAt).getTime() : 0
    const orderedAtDate = orderedAt ? getDateFromId(orderedAt).getTime() : 0
    const deliveredAtDate = deliveredAt
        ? getDateFromId(deliveredAt).getTime()
        : 0

    const createdAtEndDate = approvedAt
        ? getEndDate(createdAtDate, approvedAtDate - createdAtDate)
        : 0
    const approvedAtEndDate =
        orderedAt && approvedAt
            ? getEndDate(approvedAtDate, orderedAtDate - approvedAtDate)
            : 0
    const orderedAtEndDate =
        deliveredAt && orderedAt
            ? getEndDate(orderedAtDate, deliveredAtDate - orderedAtDate)
            : 0

    const chartData = [
        {
            task: 'GERACAO-SC',
            startDate: createdAtDate,
            endDate: createdAtEndDate,
            duration: createdAtEndDate - createdAtDate,
        },
        approvedAt && approvedAtDate > 0
            ? {
                  task: 'APROVACAO-SC',
                  startDate: approvedAtDate,
                  endDate: approvedAtEndDate,
                  duration: approvedAtEndDate - approvedAtDate,
              }
            : null,
        orderedAt && orderedAtDate > 0
            ? {
                  task: 'GERACAO-PEDIDO',
                  startDate: orderedAtDate,
                  endDate: orderedAtEndDate,
                  duration: deliveredAt
                      ? orderedAtEndDate - orderedAtDate
                      : orderedAtDate,
              }
            : null,
        deliveredAt && deliveredAtDate > 0 && orderedAtDate > 0
            ? {
                  task: 'ENTREGA-PEDIDO',
                  startDate: orderedAtEndDate,
                  endDate: deliveredAtDate,
                  duration: deliveredAtDate - orderedAtDate,
              }
            : null,
    ].filter(Boolean) as LeadtimeChartData[]

    const chartConfig = {
        'GERACAO-SC': {
            label: 'Geração da SC',
            color: 'hsl(var(--chart-priority-normal))',
        },
        'APROVACAO-SC': {
            label: 'Aprovação da SC',
            color: 'hsl(var(--chart-priority-normal))',
        },
        'GERACAO-PEDIDO': {
            label: 'Geração do pedido',
            color: 'hsl(var(--chart-priority-normal))',
        },
        'ENTREGA-PEDIDO': {
            label: 'Entrega do pedido',
            color: 'hsl(var(--chart-priority-normal))',
        },
    }

    return (
        <ChartContainer
            config={chartConfig}
            className={cn('w-[600px] rounded-md border h-[300px]', className)}
        >
            <BarChart
                layout="vertical"
                data={chartData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 60,
                    bottom: 20,
                }}
            >
                <CartesianGrid vertical={false} />
                <XAxis
                    dataKey="startDate"
                    type="number"
                    tickFormatter={dateFormater}
                    domain={[createdAtDate, deliveredAtDate]}
                    axisLine={false}
                    fontSize={14}
                    tick={false}
                    tickLine={false}
                />
                <YAxis
                    dataKey="task"
                    type="category"
                    tickMargin={35}
                    axisLine={false}
                    fontSize={12}
                    tickLine={false}
                    tickFormatter={(value) => chartConfig[value].label}
                />
                <ChartTooltip
                    labelClassName="font-semibold"
                    contentStyle={{ width: 100 }}
                    content={<ChartTooltipContent formatter={labelFormatter} />}
                />
                <Bar
                    dataKey="startDate"
                    stackId="a"
                    fill="transparent"
                    barSize={8}
                />
                <Bar
                    dataKey="duration"
                    stackId="a"
                    fill="var(--color-GERACAO-SC)"
                    barSize={8}
                    radius={4}
                >
                    <LabelList
                        dataKey="startDate"
                        position="center"
                        content={<StartDateLabel />}
                        fill="var(--color-GERACAO-SC)"
                    />
                </Bar>
                <Bar
                    dataKey="endDate"
                    stackId="a"
                    fill="transparent"
                    barSize={8}
                />
            </BarChart>
        </ChartContainer>
    )
}

export default LeadTimeChart
