import { Intervals } from '@/types/PurchaseOrder'

export default (interval: Intervals) => {
    const baseName = '--chart-status-atraso-'

    const variable: Record<Intervals, string> = {
        'ATRASO DE 1 À 5 DIAS': `${baseName}1-5-dias`,
        'ATRASO DE 11 À 15 DIAS': `${baseName}11-15-dias`,
        'ATRASO DE 16 À 25 DIAS': `${baseName}16-25-dias`,
        'ATRASO DE 6 À 10 DIAS': `${baseName}6-10-dias`,
        'ATRASO MAIOR QUE 25 DIAS': `${baseName}maior-25-dias`,
        'DENTRO DO PRAZO': `${baseName}dentro-prazo`,
        INDEFINIDO: `${baseName}indefinido`,
    }

    return variable[interval]
}
