import { QuotationMapFlatState } from '@/store/quotationMapSlice/type'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'
import { quotationMapFlatSelector } from '@/store/quotationMapSlice'
import { useCallback, useMemo } from 'react'

export type SupplierTotals = {
    supplier: string
    supplierId: string
    totalInitial: number
    totalFinal: number
    totalDifference: number
    totalTax: number
    financialSaving: number
    financialSavingPercentage: number
    negotiationSaving: number
    negotiationSavingPercentage: number
    baselineSaving: number
    baselineSavingPercentage: number
}

const stateSelector = (state: Store) => ({
    supplierMap: state.quotationMapSlice.state.quotationMap.FORNECEDORES,
})

export const useQuotationSuppliersTotals = () => {
    const { supplierMap } = useBaseStore(stateSelector)
    const quotationMap = useBaseStore(quotationMapFlatSelector)

    const suppliersMap = useMemo(
        () => Object.values(supplierMap.entities) || [],
        [supplierMap]
    )

    const getTotals = useCallback((values: QuotationMapFlatState[]) => {
        return values.reduce(
            (acc, curr) => {
                const {
                    PRECO_TOTAL,
                    PRECO_TOTAL_IMPOSTO,
                    SAVING_BASELINE,
                    SAVING_FINANCEIRO,
                    SAVING_NEGOCIACAO,
                    SAVING_BASELINE_PERCENTUAL,
                    SAVING_FINANCEIRO_PERCENTUAL,
                    SAVING_NEGOCIACAO_PERCENTUAL,
                } = curr

                const total = acc.total + Number(PRECO_TOTAL)
                const totalTax = acc.totalTax + Number(PRECO_TOTAL_IMPOSTO)
                const financialSaving =
                    acc.financialSaving + Number(SAVING_FINANCEIRO) || 0
                const negotiationSaving =
                    acc.negotiationSaving + Number(SAVING_NEGOCIACAO) || 0
                const baselineSaving =
                    acc.baselineSaving + Number(SAVING_BASELINE) || 0

                const financialSavingPercentage =
                    acc.financialSavingPercentage +
                        Number(SAVING_FINANCEIRO_PERCENTUAL) || 0
                const negotiationSavingPercentage =
                    acc.negotiationSavingPercentage +
                        Number(SAVING_NEGOCIACAO_PERCENTUAL) || 0
                const baselineSavingPercentage =
                    acc.baselineSavingPercentage +
                        Number(SAVING_BASELINE_PERCENTUAL) || 0

                return {
                    ...acc,
                    total,
                    totalTax,
                    financialSaving,
                    negotiationSaving,
                    baselineSaving,
                    financialSavingPercentage,
                    negotiationSavingPercentage,
                    baselineSavingPercentage,
                }
            },
            {
                total: 0,
                totalTax: 0,
                financialSaving: 0,
                negotiationSaving: 0,
                baselineSaving: 0,
                financialSavingPercentage: 0,
                negotiationSavingPercentage: 0,
                baselineSavingPercentage: 0,
            }
        )
    }, [])

    const suppliers = useMemo(() => {
        const normalized: Record<string, SupplierTotals> = suppliersMap.reduce(
            (acc, curr) => {
                const supplierFirstRound = Math.min(
                    ...curr.FORNECEDOR_COTACAO_RODADAS
                )
                const supplierLastRound = Math.max(
                    ...curr.FORNECEDOR_COTACAO_RODADAS
                )

                const firstRoundValues = quotationMap.filter(
                    (value) =>
                        value.SK_COTACAO_X_RODADA === supplierFirstRound &&
                        value.SK_FORNECEDOR === curr.SK_FORNECEDOR
                )

                const lastRoundValues = quotationMap.filter(
                    (value) =>
                        value.SK_COTACAO_X_RODADA === supplierLastRound &&
                        value.SK_FORNECEDOR === curr.SK_FORNECEDOR
                )

                const initialTotals = getTotals(firstRoundValues)
                const finalTotals = getTotals(lastRoundValues)

                return {
                    ...acc,
                    [curr.SK_FORNECEDOR]: {
                        supplier: curr.DD_NOME_FANTASIA,
                        supplierId: curr.SK_FORNECEDOR,
                        totalInitial: initialTotals.total,
                        totalFinal: finalTotals.total,
                        totalDifference:
                            initialTotals.total - finalTotals.total,
                        totalTax: finalTotals.totalTax,
                        financialSaving: finalTotals.financialSaving,
                        negotiationSaving: finalTotals.negotiationSaving,
                        baselineSaving: finalTotals.baselineSaving,
                        financialSavingPercentage:
                            finalTotals.financialSavingPercentage,
                        negotiationSavingPercentage:
                            finalTotals.negotiationSavingPercentage,
                        baselineSavingPercentage:
                            finalTotals.baselineSavingPercentage,
                    },
                }
            },
            {} as Record<string, SupplierTotals>
        )

        return Object.values(normalized)
    }, [quotationMap, suppliersMap])

    return { suppliers }
}
