import {
    QuotationMapProduct,
    QuotationMapValues,
} from '@/api/business/cotacoes/type'
import {
    ColumnOrderState,
    Header,
    createColumnHelper,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { useBaseStore } from '@/store'
import { quotationMapStateSelector } from '@/store/quotationMapSlice'
import { TypographyH2 } from '@/components/ui/typography'
import TableContainer from '@/components/Table/components/TableContainer'
import TableLayout from '@/components/Table/components/TableLayout'
import TableCell from '@/components/Table/components/TableCell'
import TableColumnHeader from '@/components/Table/components/TableColumnHeader'
import { TableContextProvider } from '@/components/Table/contexts/TableContext'
import { format, isValid } from 'date-fns'
import TableBody from '@/components/Table/components/TableBody'
import TableRow from '@/components/Table/components/TableRow'
import TableFooter from '@/components/Table/components/TableFooter'
import { Store } from '@/store/type'
import { QuotationMapSupplierValuesState } from '@/store/quotationMapSlice/type'
import HeaderContent from './components/HeaderContent'
import CellContent from './components/CellContent'
import { ApprovalStatus } from '@/types/Approval'
import { cn } from '@/lib/utils'
import ActionBar from './components/ActionBar'

type QuotationMapTableType = QuotationMapValues & QuotationMapProduct

const columnHelper = createColumnHelper<QuotationMapTableType>()

const stateSelector = (state: Store) => ({
    quotationMapTableVisibility:
        state.quotationMapSlice.state.quotationMapTableVisibility,
})

export const ApprovalComponent: Record<ApprovalStatus, ReactNode> = {
    APROVADO: (
        <div className="flex items-center gap-2">
            <div className="flex w-2 h-2 bg-green-500 rounded-full" />
            <span className="text-green-500">Aprovado</span>
        </div>
    ),
    RECUSADO: (
        <div className="flex items-center gap-2">
            <div className="flex w-2 h-2 bg-red-500 rounded-full" />
            <span className="text-red-500">Recusado</span>
        </div>
    ),
    PENDENTE: (
        <div className="flex items-center gap-2">
            <div className="flex w-2 h-2 bg-yellow-500 rounded-full" />
            <span className="text-yellow-500">Pendente</span>
        </div>
    ),
    EM_APROVACAO: (
        <div className="flex items-center gap-2">
            <div className="flex w-2 h-2 bg-yellow-500 rounded-full" />
            <span className="text-yellow-500">Em aprovação</span>
        </div>
    ),
}

const HEADER_CLASSNAME = 'text-xs flex items-center h-auto font-bold'

const COMMON_COLUMN_META = {
    enableColumnOrdering: false,
    enableMenu: false,
    header: {
        className: HEADER_CLASSNAME,
    },
    cell: {
        className: 'p-0 items-start',
    },
}

const TOTALS_HEADER_CLASSNAME = 'bg-accent text-accent-foreground'
const SAVINGS_HEADER_CLASSNAME = 'text-primary-foreground'
const TOTALS_CELL_CLASSNAME =
    'group-hover:bg-neutral-200 bg-accent font-semibold text-accent-foreground'
const SAVINGS_CELL_CLASSNAME =
    'group-hover:bg-primary-200 bg-primary-50 text-primary-foreground'

const QuotationMapTable = () => {
    const { quotationMapTableVisibility } = useBaseStore(stateSelector)
    const { quotationMap } = useBaseStore(quotationMapStateSelector)

    const [columnOrderState, setColumnOrderState] = useState<ColumnOrderState>(
        []
    )

    const suppliers = useMemo(
        () => Object.values(quotationMap?.FORNECEDORES.entities || []),
        [quotationMap.FORNECEDORES]
    )

    const products = useMemo(
        () => Object.values(quotationMap?.PRODUTOS.entities || []),
        [quotationMap.PRODUTOS]
    )

    const values = quotationMap?.VALORES || []

    const getValue = useCallback(
        (
            supplier: QuotationMapSupplierValuesState,
            quotationRoundId: number,
            quotationProductId: number
        ) => {
            const foundProduct = quotationMap?.VALORES.find(
                (value) =>
                    supplier.FORNECEDOR_RODADAS.includes(
                        value.SK_FORNECEDOR_X_RODADA
                    ) &&
                    quotationRoundId === value.SK_COTACAO_X_RODADA &&
                    value.SK_COTACAO_X_PRODUTO === quotationProductId
            )

            return foundProduct
        },
        [quotationMap]
    )

    const columnsMemo = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                header: 'Cod Empresa',
                size: 80,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="truncate"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                header: 'Empresa',
                size: 150,
                enableHiding: false,
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            style={{ color: COR_EMPRESA }}
                            className="truncate"
                        >
                            {getValue()}
                        </span>
                    )
                },
                meta: {
                    enableColumnOrdering: false,
                },
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                header: 'Item',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 80,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                header: 'SC',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                header: 'Produto',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                header: 'UM',
                meta: {
                    enableColumnOrdering: false,
                },
                enableHiding: false,
                size: 80,
            }),
            columnHelper.accessor(
                ({ ULTIMO_PRECO_IPCA }) =>
                    ULTIMO_PRECO_IPCA
                        ? currencyFormat(Number(ULTIMO_PRECO_IPCA))
                        : '-',
                {
                    header: 'IPCA',
                    meta: {
                        enableColumnOrdering: false,
                    },
                    enableHiding: false,
                    size: 80,
                }
            ),
            columnHelper.accessor('QTD_SOLICITADA', {
                header: 'Qtde. solicitada',
                enableHiding: false,
                size: 130,
                meta: {
                    enableColumnOrdering: false,
                },
                footer: () => 'Total',
            }),
            columnHelper.group({
                header: 'Fornecedores',
                meta: {
                    enableColumnOrdering: false,
                    enableMenu: false,
                    header: {
                        className: 'text-xs p-4 font-bold',
                    },
                },
                columns: [
                    ...suppliers
                        .filter(
                            (supplier) =>
                                quotationMapTableVisibility.suppliers[
                                    supplier.SK_FORNECEDOR
                                ]
                        )
                        .map((supplier) => {
                            return columnHelper.group({
                                id: supplier.SK_FORNECEDOR,
                                header: () => {
                                    return <HeaderContent supplier={supplier} />
                                },
                                meta: {
                                    enableColumnOrdering: false,
                                    enableMenu: false,
                                    header: {
                                        className: 'text-sm p-4 font-bold',
                                    },
                                },
                                columns: quotationMap?.RODADAS.filter(
                                    (round) =>
                                        quotationMapTableVisibility.rounds[
                                            round.SK_COTACAO_X_RODADA
                                        ]
                                ).map((round) => {
                                    const columnGroup = columnHelper.group({
                                        id: `${round.SK_COTACAO_X_RODADA}${supplier.SK_FORNECEDOR}`,
                                        header: `Rodada ${round.NUMERO_RODADA}`,
                                        meta: {
                                            enableColumnOrdering: false,
                                            enableMenu: false,
                                            header: {
                                                className:
                                                    'text-xs p-4 font-bold',
                                            },
                                        },
                                        columns: [],
                                    })

                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-status`,
                                            header: 'Aprovação',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                const status =
                                                    value.APROVACAO?.DD_STATUS

                                                return (
                                                    <div className="p-2">
                                                        {status
                                                            ? ApprovalComponent[
                                                                  status
                                                              ]
                                                            : '-'}
                                                    </div>
                                                )
                                            },
                                            meta: {
                                                ...COMMON_COLUMN_META,
                                                cell: {
                                                    className: cn(
                                                        COMMON_COLUMN_META.cell
                                                            .className,
                                                        'items-center'
                                                    ),
                                                },
                                            },
                                        })
                                    )

                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-qtde-disponivel`,
                                            header: 'Qtd. disp.',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        shouldGroup
                                                        data={value}
                                                        dataKey="QTD_DISPONIVEL"
                                                    />
                                                )
                                            },
                                            meta: COMMON_COLUMN_META,
                                            size: 80,
                                        })
                                    )

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-net`,
                                                header: 'Preço net',
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = getValue(
                                                        supplier,
                                                        round.SK_COTACAO_X_RODADA,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <CellContent
                                                            data={value}
                                                            dataKey="PRECO"
                                                            formatter={
                                                                currencyFormat
                                                            }
                                                        />
                                                    )
                                                },
                                                meta: COMMON_COLUMN_META,
                                                size: 120,
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO_IMPOSTO'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-taxes`,
                                                header: 'Preço c/ imposto',
                                                meta: COMMON_COLUMN_META,
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = values?.find(
                                                        (value) =>
                                                            supplier.FORNECEDOR_RODADAS.includes(
                                                                value.SK_FORNECEDOR_X_RODADA
                                                            ) &&
                                                            round.SK_COTACAO_X_RODADA ===
                                                                value.SK_COTACAO_X_RODADA &&
                                                            value.SK_COTACAO_X_PRODUTO ===
                                                                SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <CellContent
                                                            dataKey="PRECO_IMPOSTO"
                                                            data={value}
                                                            formatter={
                                                                currencyFormat
                                                            }
                                                        />
                                                    )
                                                },
                                                size: 120,
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_IMPOSTO
                                                                ) || 0
                                                            return (acc +=
                                                                value)
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PREVISAO_DE_ENTREGA'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-date`,
                                                header: 'Previsão de entrega',
                                                meta: COMMON_COLUMN_META,
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = getValue(
                                                        supplier,
                                                        round.SK_COTACAO_X_RODADA,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    const formatter = (
                                                        date: string
                                                    ) =>
                                                        date
                                                            ? isValid(
                                                                  new Date(date)
                                                              )
                                                                ? format(
                                                                      new Date(
                                                                          date
                                                                      ),
                                                                      'dd/MM/yyyy'
                                                                  )
                                                                : '-'
                                                            : '-'

                                                    return (
                                                        <CellContent
                                                            dataKey="PREVISAO_DE_ENTREGA"
                                                            className="justify-start"
                                                            data={value}
                                                            formatter={
                                                                formatter
                                                            }
                                                        />
                                                    )
                                                },
                                            })
                                        )
                                    }
                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-condicao-pagamento`,
                                            header: 'Condição de pagamento',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        dataKey="DS_CONDICAO_DE_PAGAMENTO"
                                                        className="justify-start"
                                                        data={value}
                                                    />
                                                )
                                            },
                                            meta: COMMON_COLUMN_META,
                                            size: 180,
                                        })
                                    )
                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-frete`,
                                            header: 'Frete',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        dataKey="FRETE"
                                                        className="justify-start"
                                                        data={value}
                                                    />
                                                )
                                            },
                                            meta: COMMON_COLUMN_META,
                                            size: 70,
                                        })
                                    )
                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-pis`,
                                            header: 'PIS Aliq.',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        dataKey="PIS"
                                                        className="justify-start"
                                                        data={value}
                                                        formatter={
                                                            percentageFormat
                                                        }
                                                    />
                                                )
                                            },
                                            meta: COMMON_COLUMN_META,
                                            size: 70,
                                        })
                                    )
                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-cofins`,
                                            header: 'COFINS Aliq.',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        dataKey="COFINS"
                                                        className="justify-start"
                                                        data={value}
                                                        formatter={
                                                            percentageFormat
                                                        }
                                                    />
                                                )
                                            },
                                            meta: COMMON_COLUMN_META,
                                            size: 95,
                                        })
                                    )
                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-icms`,
                                            header: 'ICMS Aliq.',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        dataKey="ICMS"
                                                        className="justify-start"
                                                        data={value}
                                                        formatter={
                                                            percentageFormat
                                                        }
                                                    />
                                                )
                                            },
                                            meta: COMMON_COLUMN_META,
                                            size: 80,
                                        })
                                    )
                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-ipi`,
                                            header: 'IPI Aliq.',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        dataKey="IPI"
                                                        className="justify-start"
                                                        data={value}
                                                        formatter={
                                                            percentageFormat
                                                        }
                                                    />
                                                )
                                            },
                                            meta: COMMON_COLUMN_META,
                                            size: 70,
                                        })
                                    )

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO_TOTAL'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-total`,
                                                header: 'Valor total',
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = getValue(
                                                        supplier,
                                                        round.SK_COTACAO_X_RODADA,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <CellContent
                                                            dataKey="PRECO_TOTAL"
                                                            className={
                                                                TOTALS_CELL_CLASSNAME
                                                            }
                                                            data={value}
                                                            formatter={
                                                                currencyFormat
                                                            }
                                                        />
                                                    )
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_TOTAL
                                                                ) || 0
                                                            return acc + value
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                                meta: {
                                                    ...COMMON_COLUMN_META,
                                                    header: {
                                                        className: cn(
                                                            HEADER_CLASSNAME,
                                                            TOTALS_HEADER_CLASSNAME
                                                        ),
                                                    },
                                                },
                                            })
                                        )
                                    }

                                    if (
                                        quotationMapTableVisibility.fields[
                                            'PRECO_TOTAL_IMPOSTO'
                                        ]
                                    ) {
                                        columnGroup.columns?.push(
                                            columnHelper.display({
                                                id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-total-taxes`,
                                                header: 'Valor total com imposto',
                                                cell: ({ row }) => {
                                                    const {
                                                        SK_COTACAO_X_PRODUTO,
                                                    } = row.original

                                                    const value = getValue(
                                                        supplier,
                                                        round.SK_COTACAO_X_RODADA,
                                                        SK_COTACAO_X_PRODUTO
                                                    )

                                                    if (!value) return null

                                                    return (
                                                        <CellContent
                                                            dataKey="PRECO_TOTAL_IMPOSTO"
                                                            className={
                                                                TOTALS_CELL_CLASSNAME
                                                            }
                                                            data={value}
                                                            formatter={
                                                                currencyFormat
                                                            }
                                                        />
                                                    )
                                                },
                                                footer: () => {
                                                    const sum = values
                                                        .filter((value) =>
                                                            supplier.FORNECEDOR_RODADAS.some(
                                                                (id) =>
                                                                    id ===
                                                                        value.SK_FORNECEDOR_X_RODADA &&
                                                                    round.SK_COTACAO_X_RODADA ===
                                                                        value.SK_COTACAO_X_RODADA
                                                            )
                                                        )
                                                        .reduce((acc, curr) => {
                                                            const value =
                                                                Number(
                                                                    curr.PRECO_TOTAL_IMPOSTO
                                                                ) || 0
                                                            return (acc +=
                                                                value)
                                                        }, 0)
                                                    return currencyFormat(sum)
                                                },
                                                meta: {
                                                    ...COMMON_COLUMN_META,
                                                    header: {
                                                        className: cn(
                                                            HEADER_CLASSNAME,
                                                            TOTALS_HEADER_CLASSNAME
                                                        ),
                                                    },
                                                },
                                            })
                                        )
                                    }

                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-saving`,
                                            header: 'Saving baseline',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        dataKey="SAVING_BASELINE"
                                                        data={value}
                                                        className={
                                                            SAVINGS_CELL_CLASSNAME
                                                        }
                                                        formatter={(v) =>
                                                            `${currencyFormat(
                                                                v
                                                            )} (${percentageFormat(
                                                                Number(
                                                                    value.SAVING_BASELINE_PERCENTUAL
                                                                ) * 100
                                                            )})`
                                                        }
                                                    />
                                                )
                                            },
                                            meta: {
                                                ...COMMON_COLUMN_META,
                                                header: {
                                                    className: cn(
                                                        HEADER_CLASSNAME,
                                                        SAVINGS_HEADER_CLASSNAME
                                                    ),
                                                },
                                            },
                                            footer: () => {
                                                const sum = values
                                                    .filter((value) =>
                                                        supplier.FORNECEDOR_RODADAS.some(
                                                            (id) =>
                                                                id ===
                                                                    value.SK_FORNECEDOR_X_RODADA &&
                                                                round.SK_COTACAO_X_RODADA ===
                                                                    value.SK_COTACAO_X_RODADA
                                                        )
                                                    )
                                                    .reduce((acc, curr) => {
                                                        const value =
                                                            Number(
                                                                curr.SAVING_BASELINE
                                                            ) || 0
                                                        return acc + value
                                                    }, 0)
                                                return currencyFormat(sum)
                                            },
                                        })
                                    )
                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-saving-negociacao`,
                                            header:
                                                suppliers.length > 1
                                                    ? 'Saving negociação'
                                                    : 'Perf. Comprador',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        dataKey="SAVING_NEGOCIACAO"
                                                        className={
                                                            SAVINGS_CELL_CLASSNAME
                                                        }
                                                        data={value}
                                                        formatter={(v) =>
                                                            `${currencyFormat(
                                                                v
                                                            )} (${percentageFormat(
                                                                Number(
                                                                    value.SAVING_NEGOCIACAO_PERCENTUAL
                                                                ) * 100
                                                            )})`
                                                        }
                                                    />
                                                )
                                            },
                                            meta: {
                                                ...COMMON_COLUMN_META,
                                                header: {
                                                    className: cn(
                                                        HEADER_CLASSNAME,
                                                        SAVINGS_HEADER_CLASSNAME
                                                    ),
                                                },
                                            },
                                            footer: () => {
                                                const sum = values
                                                    .filter((value) =>
                                                        supplier.FORNECEDOR_RODADAS.some(
                                                            (id) =>
                                                                id ===
                                                                    value.SK_FORNECEDOR_X_RODADA &&
                                                                round.SK_COTACAO_X_RODADA ===
                                                                    value.SK_COTACAO_X_RODADA
                                                        )
                                                    )
                                                    .reduce((acc, curr) => {
                                                        const value =
                                                            Number(
                                                                curr.SAVING_NEGOCIACAO
                                                            ) || 0
                                                        return acc + value
                                                    }, 0)
                                                return currencyFormat(sum)
                                            },
                                        })
                                    )
                                    columnGroup.columns?.push(
                                        columnHelper.display({
                                            id: `${supplier.SK_FORNECEDOR}${round.SK_COTACAO_X_RODADA}-saving-financeiro`,
                                            header: 'Saving financeiro',
                                            cell: ({ row }) => {
                                                const { SK_COTACAO_X_PRODUTO } =
                                                    row.original

                                                const value = getValue(
                                                    supplier,
                                                    round.SK_COTACAO_X_RODADA,
                                                    SK_COTACAO_X_PRODUTO
                                                )

                                                if (!value) return null

                                                return (
                                                    <CellContent
                                                        dataKey="SAVING_FINANCEIRO"
                                                        className={
                                                            SAVINGS_CELL_CLASSNAME
                                                        }
                                                        data={value}
                                                        formatter={(v) =>
                                                            `${currencyFormat(
                                                                v
                                                            )} (${percentageFormat(
                                                                value.SAVING_FINANCEIRO_PERCENTUAL ||
                                                                    0
                                                            )})`
                                                        }
                                                    />
                                                )
                                            },
                                            meta: {
                                                ...COMMON_COLUMN_META,
                                                header: {
                                                    className: cn(
                                                        HEADER_CLASSNAME,
                                                        SAVINGS_HEADER_CLASSNAME
                                                    ),
                                                },
                                            },
                                            footer: () => {
                                                const sum = values
                                                    .filter((value) =>
                                                        supplier.FORNECEDOR_RODADAS.some(
                                                            (id) =>
                                                                id ===
                                                                    value.SK_FORNECEDOR_X_RODADA &&
                                                                round.SK_COTACAO_X_RODADA ===
                                                                    value.SK_COTACAO_X_RODADA
                                                        )
                                                    )
                                                    .reduce((acc, curr) => {
                                                        const value =
                                                            Number(
                                                                curr.SAVING_FINANCEIRO
                                                            ) || 0
                                                        return acc + value
                                                    }, 0)
                                                return currencyFormat(sum)
                                            },
                                        })
                                    )

                                    return columnGroup
                                }),
                            })
                        }),
                ],
            }),
        ],
        [quotationMap, quotationMapTableVisibility]
    )

    const dataMemo = useMemo(() => {
        return products.map((product) => {
            return {
                ...product,
                ...values.find(
                    (value) =>
                        value.SK_COTACAO_X_PRODUTO ===
                        product.SK_COTACAO_X_PRODUTO
                )!,
            }
        })
    }, [quotationMap])

    const table = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        filterFromLeafRows: true,
        columnResizeMode: 'onChange',
        state: {
            columnOrder: columnOrderState,
        },
        meta: {
            layout: 'stretch',
        },
        onColumnOrderChange: setColumnOrderState,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
    })

    const rows = table.getRowModel().rows

    return (
        <>
            <div className="flex items-center justify-between">
                <TypographyH2>Mapa</TypographyH2>
                <ActionBar />
            </div>
            <TableContainer>
                <TableContextProvider table={table}>
                    <TableLayout>
                        {/* thead */}
                        <div className="bg-background">
                            {table.getHeaderGroups().map((headerGroup) => (
                                // tr
                                <div key={headerGroup.id} className="flex">
                                    {headerGroup.headers.map((header) => (
                                        // th
                                        <TableColumnHeader
                                            key={header.id}
                                            header={header}
                                        />
                                    ))}
                                </div>
                            ))}
                        </div>
                        {/* tbody */}
                        <TableBody>
                            {rows.map((row) => {
                                return (
                                    // tr
                                    <TableRow
                                        className="flex border-b"
                                        key={row?.id}
                                    >
                                        {row.getVisibleCells().map((cell) => {
                                            return (
                                                // td
                                                <TableCell
                                                    key={cell.id}
                                                    cell={cell}
                                                    className="truncate"
                                                />
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <div>
                            {table.getFooterGroups().map((footerGroup) => (
                                <div key={footerGroup.id} className="flex">
                                    {footerGroup.headers.map(
                                        (header) =>
                                            header.depth === 4 && (
                                                <TableFooter
                                                    key={header.id}
                                                    header={
                                                        header as Header<
                                                            unknown,
                                                            any
                                                        >
                                                    }
                                                />
                                            )
                                    )}
                                </div>
                            ))}
                        </div>
                    </TableLayout>
                </TableContextProvider>
            </TableContainer>
        </>
    )
}

export default QuotationMapTable
