import { ReactNode } from 'react'
import { TypographyP } from '../ui/typography'
import { isAxiosError } from '@/api/business'

type ComponentLoaderProps = {
    isLoading: boolean
    isError: boolean
    error: Error | null
    fallback?: ReactNode
    children: ReactNode
}

const ComponentLoader = ({
    children,
    error,
    isError,
    isLoading,
    fallback,
}: ComponentLoaderProps) => {
    if (isLoading) {
        return fallback || null
    }

    if (isError) {
        let errorMessage = 'Erro ao buscar dados'

        if (isAxiosError(error)) {
            errorMessage =
                error.response?.data?.message || error.message || errorMessage
        }

        return (
            <div>
                <TypographyP className="text-center text-destructive">
                    {errorMessage}
                </TypographyP>
            </div>
        )
    }

    return children
}

export default ComponentLoader
