import { Dialog, DialogContent } from '@/components/ui/dialog'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import QuotationMapContent from '../QuotationMapContent'

const stateSelector = (state: Store) => ({
    isFullscreen: state.quotationMapSlice.state.isFullscreen,
    onToggleFullscreen:
        state.quotationMapSlice.actions.onQuotationMapToggleFullscreen,
})

const QuotationMapDialog = () => {
    const { isFullscreen, onToggleFullscreen } = useBaseStore(stateSelector)

    return (
        <Dialog open={isFullscreen} onOpenChange={onToggleFullscreen}>
            <DialogContent className="flex flex-col w-screen h-screen overflow-hidden sm:rounded-none max-w-screen">
                <QuotationMapContent />
            </DialogContent>
        </Dialog>
    )
}

export default QuotationMapDialog
