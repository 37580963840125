import { TypographyMuted, TypographyP } from '@/components/ui/typography'

type FieldProps = {
    value: string
    label: string
}

const Field = ({ label, value }: FieldProps) => {
    return (
        <div className="-space-y-0.5">
            <TypographyMuted>{label}</TypographyMuted>
            <TypographyP className="text-accent-foreground">
                {value}
            </TypographyP>
        </div>
    )
}

export default Field
