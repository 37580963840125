import {
    fetchBuyersPerformance,
    fetchIntervalTotals,
    fetchLeadTime,
    fetchPriorityTotals,
    fetchPurchaseRequest,
    fetchPurchaseRequestFilters,
    fetchPurchaseRequestItems,
    fetchPurchaseRequestToSelect,
    fetchQuotationPurchaseRequest,
    fetchStatusTotals,
} from '@/api/business/solicitacaoDeCompra'
import {
    FetchLeadTimeProps,
    FetchPurchaseRequestItemsProps,
    FetchPurchaseRequestProps,
    FetchPurchaseRequestToSelectProps,
    FetchQuotationPurchaseRequestProps,
    PurchaseRequestFlat,
    FetchPriorityTotalsProps,
    FetchBuyersPerformanceProps,
    FetchIntervalTotalsProps,
    FetchStatusTotalsProps,
} from '@/api/business/solicitacaoDeCompra/type'
import { Filter } from '@/types/Filters'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const purchaseRequestKeys = {
    all: ['purchaseRequest'] as const,
    lists: () => [...purchaseRequestKeys.all, 'list'] as const,
    listQuotation: (params: {
        perPage: number
        currentPage: number
        filters?: any
    }) => [...purchaseRequestKeys.lists(), 'quotation', params] as const,
    list: (params: { perPage: number; currentPage: number; filters?: any }) =>
        [...purchaseRequestKeys.lists(), params] as const,
    details: () => [...purchaseRequestKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...purchaseRequestKeys.details(), params] as const,
    filters: () => [...purchaseRequestKeys.all, 'filters'] as const,
    toSelect: (filters: Filter<PurchaseRequestFlat>[]) =>
        [...purchaseRequestKeys.all, 'toSelect', filters] as const,
    totals: (
        type: 'status' | 'priority' | 'interval',
        startDate: Date,
        endDate: Date
    ) => [
        ...purchaseRequestKeys.all,
        'totals',
        type,
        startDate.toLocaleDateString(),
        endDate.toLocaleDateString(),
    ],
    leadTime: ({
        purchaseOrderId,
        purchaseRequestId,
    }: {
        purchaseRequestId?: string
        purchaseOrderId?: string
    }) =>
        purchaseRequestId
            ? [...purchaseRequestKeys.all, 'leadtime', 'sc', purchaseRequestId]
            : [...purchaseRequestKeys.all, 'leadtime', 'pc', purchaseOrderId],
    items: (companyId: string, purchaseRequestNumber: string) => [
        ...purchaseRequestKeys.all,
        'items',
        companyId,
        purchaseRequestNumber,
    ],
    buyersPerformance: (startDate: Date, endDate: Date) => [
        ...purchaseRequestKeys.all,
        'buyers-performance',
        startDate.toLocaleDateString(),
        endDate.toLocaleDateString(),
    ],
} as const

interface UseQuotationPurchaseRequestListQueryProps
    extends FetchQuotationPurchaseRequestProps {
    enabled: boolean
}

type UsePurchaseRequestListQueryProps = FetchPurchaseRequestProps & {
    enabled: boolean
}

export const usePurchaseRequestListQuery = ({
    currentPage,
    perPage,
    filters,
    enabled,
}: UsePurchaseRequestListQueryProps) => {
    return useQuery({
        queryFn: () => fetchPurchaseRequest({ currentPage, perPage, filters }),
        queryKey: purchaseRequestKeys.list({
            currentPage,
            perPage,
            filters,
        }),
        placeholderData: keepPreviousData,
        enabled,
    })
}
export const useQuotationPurchaseRequestListQuery = ({
    currentPage,
    perPage,
    filters,
    enabled,
}: UseQuotationPurchaseRequestListQueryProps) => {
    return useQuery({
        queryFn: () =>
            fetchQuotationPurchaseRequest({ currentPage, perPage, filters }),
        queryKey: purchaseRequestKeys.listQuotation({
            currentPage,
            perPage,
            filters,
        }),
        placeholderData: keepPreviousData,
        enabled,
    })
}

export const usePurchaseRequestFiltersQuery = () => {
    return useQuery({
        queryFn: fetchPurchaseRequestFilters,
        queryKey: purchaseRequestKeys.filters(),
    })
}

type UsePurchaseRequestToSelectProps = FetchPurchaseRequestToSelectProps & {
    enabled?: boolean
}

export const usePurchaseRequestToSelectQuery = ({
    filters,
    enabled,
}: UsePurchaseRequestToSelectProps) => {
    return useQuery({
        queryFn: () => fetchPurchaseRequestToSelect({ filters }),
        queryKey: purchaseRequestKeys.toSelect(filters),
        enabled,
    })
}

export const usePriorityTotals = ({
    startDate,
    endDate,
}: FetchPriorityTotalsProps) => {
    return useQuery({
        queryFn: () => fetchPriorityTotals({ endDate, startDate }),
        queryKey: purchaseRequestKeys.totals('priority', startDate, endDate),
    })
}

export const useIntervalTotals = ({
    startDate,
    endDate,
}: FetchIntervalTotalsProps) => {
    return useQuery({
        queryFn: () => fetchIntervalTotals({ endDate, startDate }),
        queryKey: purchaseRequestKeys.totals('interval', startDate, endDate),
    })
}

export const useStatusTotals = ({
    startDate,
    endDate,
}: FetchStatusTotalsProps) => {
    return useQuery({
        queryFn: () => fetchStatusTotals({ endDate, startDate }),
        queryKey: purchaseRequestKeys.totals('status', startDate, endDate),
    })
}

export const useBuyersPerformanceQuery = ({
    startDate,
    endDate,
}: FetchBuyersPerformanceProps) => {
    return useQuery({
        queryFn: () => fetchBuyersPerformance({ endDate, startDate }),
        queryKey: purchaseRequestKeys.buyersPerformance(startDate, endDate),
    })
}

export const usePurchaseRequestItems = (
    props: FetchPurchaseRequestItemsProps
) => {
    return useQuery({
        queryFn: () => fetchPurchaseRequestItems(props),
        queryKey: purchaseRequestKeys.items(
            props.companyId,
            props.purchaseRequestNumber
        ),
    })
}

export const usePurchaseRequestLeadTimeQuery = (props: FetchLeadTimeProps) => {
    return useQuery({
        queryFn: () => fetchLeadTime(props),
        queryKey: purchaseRequestKeys.leadTime(props),
    })
}
