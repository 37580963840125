import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { cn } from '@/lib/utils'
import { Filter, Operators } from '@/types/Filters'
import { Quotation } from '@/types/Quotation'
import { QuotationStatusColorScheme } from '@/types/QuotationStatus'
import { getIdFromDate } from '@/utils/date'
import { Row, createColumnHelper } from '@tanstack/react-table'
import { format, isAfter, isValid } from 'date-fns'
import { Clock } from 'lucide-react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../Header'
import { usePaginatedQuotationQuery } from '@/queries/useQuotationQuery'
import { isAxiosError } from 'axios'
import HeaderActions from '../HeaderActions'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { useTable } from '@/hooks/useTable'

type CotacaoTable = TableData<Quotation>
const columnHelper = createColumnHelper<Quotation>()

interface ListProps {
    buttonFilters: Filter<Quotation>[]
    setButtonFilters: (
        filter: Filter<Pick<Quotation, 'SK_USUARIO' | 'SK_COTACAO'>>[]
    ) => void
}

const List = ({ buttonFilters, setButtonFilters }: ListProps) => {
    const { pageIndex, pageSize, onPaginationChange, onColumnFiltersChange } =
        useTable()

    const [fieldFilters, setFieldFilters] = useState<Filter<Quotation>[]>([])

    const { data, isPending, isError, isFetching, error, refetch } =
        usePaginatedQuotationQuery({
            page: pageIndex,
            filters: [...fieldFilters, ...buttonFilters],
            perPage: pageSize,
        })

    const navigate = useNavigate()

    const dataMemo = useMemo(() => data?.DADOS || [], [data?.DADOS])

    const columnMemo = useMemo(
        () => [
            columnHelper.accessor('NUMERO_DA_COTACAO', {
                id: 'NUMERO_DA_COTACAO',
                header: '#',
                size: 100,
                meta: {
                    cell: {
                        className: 'font-bold',
                    },
                },
            }),
            columnHelper.accessor('NM_COTACAO', {
                id: 'NM_COTACAO',
                header: 'Cotação',
                size: 250,
            }),
            columnHelper.accessor('DS_COTACAO', {
                id: 'DS_COTACAO',
                header: 'Descrição',
                size: 150,
            }),
            columnHelper.accessor('STATUS', {
                id: 'STATUS',
                header: 'Status',
                cell: ({ getValue }) => {
                    const status = getValue()
                    const MAX_STATUS_SHOWN = 1
                    const remainingStatus = Math.max(
                        0,
                        status.length - MAX_STATUS_SHOWN
                    )
                    return (
                        <div className="flex items-center gap-1.5 overflow-hidden">
                            {status
                                ?.slice(0, MAX_STATUS_SHOWN)
                                .map((status) => {
                                    return (
                                        <span
                                            key={status}
                                            className={cn(
                                                'rounded-sm truncate text-xs',
                                                QuotationStatusColorScheme[
                                                    status
                                                ]
                                            )}
                                        >
                                            {status}
                                        </span>
                                    )
                                })}
                            {!!remainingStatus && (
                                <ButtonWithTooltip
                                    align="start"
                                    className="flex items-center justify-center w-6 h-6 text-xs border rounded-md border-neutral-400 text-neutral-600"
                                    tooltipContent={status
                                        .slice(MAX_STATUS_SHOWN, status.length)
                                        .map((status, idx) => (
                                            <span key={idx} className="block">
                                                {status}
                                            </span>
                                        ))}
                                >
                                    +{remainingStatus}
                                </ButtonWithTooltip>
                            )}
                        </div>
                    )
                },
                size: 150,
                filterFn: (row, _, value) => {
                    return row.original.STATUS.map((status) =>
                        status.trim().toLowerCase()
                    ).some((status) =>
                        status.includes(value?.trim().toLowerCase())
                    )
                },
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                cell: ({ row, getValue }) => {
                    const { COR_EMPRESA } = row.original
                    return (
                        <p
                            className="overflow-hidden whitespace-nowrap text-ellipsis"
                            style={{
                                color: COR_EMPRESA || undefined,
                            }}
                        >
                            {getValue()}
                        </p>
                    )
                },
                size: 150,
            }),
            columnHelper.accessor('NUMERO_RODADA', {
                id: 'NUMERO_RODADA',
                header: 'Rodada',
                size: 100,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'SC',
                size: 100,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod Produto',
                size: 100,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                id: 'DD_RAZAO_SOCIAL',
                header: 'Razão social',
                size: 300,
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                id: 'DD_NOME_FANTASIA',
                header: 'Nome fantasia',
                size: 200,
            }),
            columnHelper.accessor('NOME_USUARIO', {
                id: 'NOME_USUARIO',
                header: 'Comprador',
                size: 200,
            }),
            columnHelper.accessor(
                ({ DT_EXPIRACAO }) =>
                    DT_EXPIRACAO
                        ? format(new Date(DT_EXPIRACAO), 'dd/MM/yyyy')
                        : null,
                {
                    id: 'DT_EXPIRACAO',
                    header: 'Expiração',
                    cell: ({ getValue, row }) => {
                        const { DT_EXPIRACAO } = row.original
                        return (
                            getValue() && (
                                <span
                                    className={cn(
                                        'flex items-center gap-2 overflow-hidden',
                                        isAfter(
                                            new Date(),
                                            new Date(DT_EXPIRACAO)
                                        ) && 'text-red-500'
                                    )}
                                >
                                    <Clock size={14} />
                                    <span className="flex-1 overflow-hidden text-ellipsis">
                                        {getValue()}
                                    </span>
                                </span>
                            )
                        )
                    },
                    size: 200,
                }
            ),
            columnHelper.accessor('CREATED_AT', {
                id: 'CREATED_AT',
                header: 'Criado em',
                cell: ({ getValue }) => {
                    return (
                        <p className="overflow-hidden whitespace-nowrap text-ellipsis">
                            {getValue()}
                        </p>
                    )
                },
                size: 150,
            }),
        ],
        []
    )

    const onRowClick = ({ row }: { row: Row<Quotation> }) => {
        navigate(`/cotacoes/${row.original.SK_COTACAO}`)
    }

    return (
        <Table<CotacaoTable>
            data={dataMemo}
            columns={columnMemo}
            tableHeader={
                <Header
                    refetch={refetch}
                    setButtonFilters={setButtonFilters}
                    data={data?.DADOS}
                />
            }
            tableActions={<HeaderActions />}
            tableState={{
                pagination: {
                    pageIndex,
                    pageSize,
                },
            }}
            getRowId={(row) =>
                `${row.SK_COTACAO}${row.SK_COTACAO_X_RODADA}${row.SK_COTACAO_X_PRODUTO}${row.SK_FORNECEDOR_X_RODADA}`
            }
            meta={{
                layout: 'stretch',
            }}
            isLoading={isPending}
            isFetching={isFetching}
            isError={isError}
            errorMessage={
                isError && isAxiosError(error)
                    ? error.response?.data.message
                    : undefined
            }
            onColumnFiltersChange={(columnFilters) => {
                if (columnFilters.length > 0) {
                    const filter: Filter<Quotation>[] = [
                        {
                            AND: columnFilters.map((filter) => {
                                if (filter.id === 'EXPIRACAO') {
                                    const dateString = (filter.value as string)
                                        .split('/')
                                        .map(Number)

                                    const date = new Date(
                                        dateString[2],
                                        dateString[1] - 1,
                                        dateString[0]
                                    )

                                    const formattedDate = isValid(date)
                                        ? getIdFromDate(date).join('')
                                        : (filter.value as string)

                                    return {
                                        field: filter.id as keyof Quotation,
                                        value: formattedDate,
                                        operator: Operators.equals,
                                    }
                                }

                                return {
                                    field: filter.id as keyof Quotation,
                                    value: filter.value as string,
                                    operator: Operators.like,
                                }
                            }),
                        },
                    ]

                    setFieldFilters(filter)
                } else {
                    setFieldFilters([])
                }

                onColumnFiltersChange(columnFilters)
            }}
            onRowClick={onRowClick}
            onPaginationChange={onPaginationChange}
            pagination={{
                pageSize,
                totalItems: data?.TOTAL || 0,
                totalPages: data?.TOTAL_DE_PAGINAS || 0,
            }}
            defaultColumn={{
                cell: ({ getValue }) => (
                    <DefaultColumn>{getValue() as string}</DefaultColumn>
                ),
            }}
        />
    )
}

export default List
