import { Intervals, Priority } from '@/api/business/solicitacaoDeCompra/type'
import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import { DatePickerWithRange } from '@/components/DatePickerWithRange'
import Select from '@/components/Select'
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'
import { usePurchaseRequestFiltersQuery } from '@/queries/usePurchaseRequestQuery'
import { useBaseStore } from '@/store'
import {
    initialFiltersState,
    quotationPurchaseRequestActionsSelector,
} from '@/store/quotationPurchaseRequestSlice'
import { OnAddFilterFn } from '@/store/quotationPurchaseRequestSlice/type'
import { FilterX } from 'lucide-react'
import { useState } from 'react'

const SectionPurchaseRequestFilters = () => {
    const [
        {
            approvalDate,
            emissionDate,
            interval,
            necessityDate,
            priority,
            buyer,
        },
        setFilter,
    ] = useState(initialFiltersState)

    const { data, isPending, isError } = usePurchaseRequestFiltersQuery()

    const { onAddFilter, onResetFilters } = useBaseStore(
        quotationPurchaseRequestActionsSelector
    )

    const onSetFilter: OnAddFilterFn = (field, value) => {
        setFilter((prev) => ({ ...prev, [field]: value }))
    }

    const onApplyFilters = () => {
        onAddFilter('approvalDate', approvalDate)
        onAddFilter('emissionDate', emissionDate)
        onAddFilter('interval', interval)
        onAddFilter('necessityDate', necessityDate)
        onAddFilter('priority', priority)
        onAddFilter('buyer', buyer)
    }

    const onResetAll = () => {
        onResetFilters()
        setFilter(initialFiltersState)
    }

    return (
        <div className="px-4 py-2">
            <Accordion type="single" collapsible defaultValue={'filter'}>
                <AccordionItem value="filter">
                    <AccordionTrigger className="px-4 rounded-md text-accent-foreground bg-accent">
                        Filtros
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className="flex flex-wrap items-end gap-4 p-4 py-0 space-y-3">
                            <div className="flex-1 space-y-1 sm:max-w-[200px]">
                                <Label>Visualização</Label>
                                <Select
                                    options={[
                                        {
                                            label: 'SCs do comprador',
                                            value: 'true',
                                        },
                                        { label: 'Todas SCs', value: 'false' },
                                    ]}
                                    placeholder={
                                        isPending
                                            ? 'Carregando...'
                                            : isError
                                            ? 'Erro ao carregar filtros'
                                            : 'Selecione uma opção'
                                    }
                                    disabled={isPending || isError}
                                    value={buyer ? 'true' : 'false'}
                                    onChange={(value) => {
                                        onSetFilter('buyer', value === 'true')
                                    }}
                                />
                            </div>
                            <div className="flex-1 space-y-1">
                                <Label>Intervalo</Label>
                                <Select
                                    options={
                                        data?.INTERVALO_PRAZO.map(
                                            (interval) => ({
                                                label: interval,
                                                value: interval,
                                            })
                                        ) || []
                                    }
                                    placeholder={
                                        isPending
                                            ? 'Carregando...'
                                            : isError
                                            ? 'Erro ao carregar filtros'
                                            : 'Selecione uma opção'
                                    }
                                    disabled={isPending || isError}
                                    value={interval}
                                    onChange={(value) => {
                                        onSetFilter(
                                            'interval',
                                            value as Intervals
                                        )
                                    }}
                                />
                            </div>
                            <div className="flex-1 space-y-1">
                                <Label>Prioridade</Label>
                                <Select
                                    options={
                                        data?.URGENCIA.map((priority) => ({
                                            label: priority,
                                            value: priority,
                                        })) || []
                                    }
                                    placeholder={
                                        isPending
                                            ? 'Carregando...'
                                            : isError
                                            ? 'Erro ao carregar filtros'
                                            : 'Selecione uma opção'
                                    }
                                    disabled={isPending || isError}
                                    value={priority}
                                    onChange={(value) => {
                                        onSetFilter(
                                            'priority',
                                            value as Priority
                                        )
                                    }}
                                />
                            </div>
                            <div className="flex-1 space-y-1">
                                <Label>Data de emissão</Label>
                                <DatePickerWithRange
                                    className="w-full"
                                    date={
                                        emissionDate && {
                                            from: new Date(emissionDate.from),
                                            to: new Date(emissionDate.to),
                                        }
                                    }
                                    setDate={(date) => {
                                        onSetFilter('emissionDate', {
                                            from:
                                                date?.from?.toString() ||
                                                new Date().toString(),
                                            to:
                                                date?.to?.toString() ||
                                                new Date().toString(),
                                        })
                                    }}
                                />
                            </div>
                            <div className="flex-1 space-y-1">
                                <Label>Data de aprovação</Label>
                                <DatePickerWithRange
                                    className="w-full"
                                    date={
                                        approvalDate && {
                                            from: new Date(approvalDate.from),
                                            to: new Date(approvalDate.to),
                                        }
                                    }
                                    setDate={(date) => {
                                        onSetFilter('approvalDate', {
                                            from:
                                                date?.from?.toString() ||
                                                new Date().toString(),
                                            to:
                                                date?.to?.toString() ||
                                                new Date().toString(),
                                        })
                                    }}
                                />
                            </div>
                            <div className="flex-1 space-y-1">
                                <Label>Data de necessidade</Label>
                                <DatePickerWithRange
                                    className="w-full"
                                    date={
                                        necessityDate && {
                                            from: new Date(necessityDate.from),
                                            to: new Date(necessityDate.to),
                                        }
                                    }
                                    setDate={(date) => {
                                        onSetFilter('necessityDate', {
                                            from:
                                                date?.from?.toString() ||
                                                new Date().toString(),
                                            to:
                                                date?.to?.toString() ||
                                                new Date().toString(),
                                        })
                                    }}
                                />
                            </div>
                            <div className="flex items-center gap-2 ml-auto">
                                <Button
                                    className={cn(
                                        'h-8',
                                        (isPending || isError) &&
                                            'bg-neutral-100 text-neutral-400 cursor-not-allowed'
                                    )}
                                    onClick={onApplyFilters}
                                    disabled={isPending || isError}
                                >
                                    Aplicar
                                </Button>
                                <ButtonWithTooltip
                                    className="flex items-center justify-center text-sm"
                                    onClick={onResetAll}
                                    disabled={isPending || isError}
                                    size="icon"
                                    variant="outline"
                                    tooltipContent={
                                        <TypographyP className="text-white">
                                            Resetar filtros
                                        </TypographyP>
                                    }
                                >
                                    <FilterX size={14} />
                                </ButtonWithTooltip>
                            </div>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default SectionPurchaseRequestFilters
