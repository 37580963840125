import { PurchaseRequestFlat } from '@/api/business/solicitacaoDeCompra/type'
import { Button } from '@/components/ui/button'
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from '@/components/ui/drawer'
import { Eye } from 'lucide-react'
import LeadTimeDashboard from '@/components/LeadTimeDashboard'
import DetailsSection from './components/DetailsSection'

type LeadTimeDrawerProps = {
    purchaseRequest: PurchaseRequestFlat
}

const LeadTimeDrawer = ({ purchaseRequest }: LeadTimeDrawerProps) => {
    return (
        <Drawer>
            <DrawerTrigger asChild>
                <Button variant="ghost" size="icon" className="mx-auto">
                    <Eye />
                </Button>
            </DrawerTrigger>
            <DrawerContent className="h-full  max-h-[90vh]">
                <div className="flex h-full gap-4 p-6 shrink-0">
                    <div className="h-full space-y-1 rounded-md bg-accent">
                        <DrawerHeader className="pb-4">
                            <DrawerTitle>
                                Solicitação de compras - #
                                {purchaseRequest.NK_SOLICITACAO_DE_COMPRAS}
                            </DrawerTitle>
                            <DrawerDescription>
                                Detalhamento e leadtime da solicitação de
                                compras{' '}
                                {purchaseRequest.NK_SOLICITACAO_DE_COMPRAS}
                            </DrawerDescription>
                        </DrawerHeader>
                        <DetailsSection purchaseRequest={purchaseRequest} />
                    </div>
                    <LeadTimeDashboard
                        purchaseRequestId={
                            purchaseRequest.SK_SOLICITACAO_DE_COMPRAS
                        }
                    />
                </div>
            </DrawerContent>
        </Drawer>
    )
}
export default LeadTimeDrawer
