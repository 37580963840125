import {
    fetchBuyersPerformance,
    fetchIntervalTotals,
    fetchPriorityTotals,
    fetchPurchaseOrder,
    fetchStatusTotals,
} from '@/api/business/pedidosDeCompra'
import {
    FetchPriorityTotalsProps,
    FetchPurchaseOrderProps,
    FetchBuyersPerformanceProps,
    FetchIntervalTotalsProps,
    FetchStatusTotalsProps,
} from '@/api/business/pedidosDeCompra/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const purchaseOrderKeys = {
    all: ['purchaseOrder'] as const,
    lists: () => [...purchaseOrderKeys.all, 'list'] as const,
    list: (params: { perPage: number; currentPage: number; filters?: any }) =>
        [...purchaseOrderKeys.lists(), params] as const,
    details: () => [...purchaseOrderKeys.all, 'detail'] as const,
    detail: (params: string) =>
        [...purchaseOrderKeys.details(), params] as const,
    filters: () => [...purchaseOrderKeys.all, 'filters'] as const,
    totals: (
        type: 'status' | 'priority' | 'interval',
        startDate: Date,
        endDate: Date
    ) => [
        ...purchaseOrderKeys.all,
        'totals',
        type,
        startDate.toLocaleDateString(),
        endDate.toLocaleDateString(),
    ],
    leadTime: ({
        purchaseOrderId,
        purchaseRequestId,
    }: {
        purchaseRequestId?: string
        purchaseOrderId?: string
    }) =>
        purchaseRequestId
            ? [...purchaseOrderKeys.all, 'leadtime', 'sc', purchaseRequestId]
            : [...purchaseOrderKeys.all, 'leadtime', 'pc', purchaseOrderId],
    buyersPerformance: (startDate: Date, endDate: Date) => [
        ...purchaseOrderKeys.all,
        'buyers-performance',
        startDate.toLocaleDateString(),
        endDate.toLocaleDateString(),
    ],
}

interface UseQuotationPurchaseOrderListQueryProps
    extends FetchPurchaseOrderProps {
    enabled: boolean
}

export const useQuotationPurchaseOrderListQuery = ({
    currentPage,
    perPage,
    filters,
    enabled,
}: UseQuotationPurchaseOrderListQueryProps) => {
    return useQuery({
        queryFn: () => fetchPurchaseOrder({ currentPage, perPage, filters }),
        queryKey: purchaseOrderKeys.list({
            currentPage,
            perPage,
            filters,
        }),
        placeholderData: keepPreviousData,
        enabled,
    })
}

export const usePriorityTotals = ({
    startDate,
    endDate,
}: FetchPriorityTotalsProps) => {
    return useQuery({
        queryFn: () => fetchPriorityTotals({ endDate, startDate }),
        queryKey: purchaseOrderKeys.totals('priority', startDate, endDate),
    })
}

export const useIntervalTotals = ({
    startDate,
    endDate,
}: FetchIntervalTotalsProps) => {
    return useQuery({
        queryFn: () => fetchIntervalTotals({ endDate, startDate }),
        queryKey: purchaseOrderKeys.totals('interval', startDate, endDate),
    })
}

export const useStatusTotals = ({
    startDate,
    endDate,
}: FetchStatusTotalsProps) => {
    return useQuery({
        queryFn: () => fetchStatusTotals({ endDate, startDate }),
        queryKey: purchaseOrderKeys.totals('status', startDate, endDate),
    })
}

export const useBuyersPerformanceQuery = ({
    startDate,
    endDate,
}: FetchBuyersPerformanceProps) => {
    return useQuery({
        queryFn: () => fetchBuyersPerformance({ endDate, startDate }),
        queryKey: purchaseOrderKeys.buyersPerformance(startDate, endDate),
    })
}
