import instance from '@/api/business'
import {
    FetchInvoiceByIdProps,
    FetchInvoiceByPurchaseOrderProps,
    FetchInvoicesPaginatedProps,
    FetchInvoicesPaginatedResponse,
    Invoice,
} from './type'

export const fetchInvoicesPaginated = async ({
    currentPage,
    perPage,
    filters,
}: FetchInvoicesPaginatedProps) => {
    const response = await instance.post<FetchInvoicesPaginatedResponse>(
        '/notas-fiscais',
        {
            PAGINA_ATUAL: currentPage,
            POR_PAGINA: perPage,
            FILTROS: filters,
        }
    )

    return response.data
}

export const fetchInvoiceById = async ({
    invoiceId,
}: FetchInvoiceByIdProps) => {
    const response = await instance.post<Invoice>('/notas-fiscais/id', {
        SK_NF: invoiceId,
    })

    return response.data
}

export const fetchInvoiceByPurchaseOrder = async ({
    purchaseOrderId,
}: FetchInvoiceByPurchaseOrderProps) => {
    const response = await instance.post<Invoice[]>(
        '/notas-fiscais/pedido-de-compras',
        {
            SK_PEDIDO_DE_COMPRAS: purchaseOrderId,
        }
    )

    return response.data
}
