import { Separator } from '@/components/ui/separator'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import useDisclosure from '@/hooks/useDisclosure'
import { cn } from '@/lib/utils'
import { ChevronDown } from 'lucide-react'
import { createContext, ReactNode, useContext } from 'react'

type SectionLabelProps = {
    children: ReactNode
}

export const SectionLabel = ({ children }: SectionLabelProps) => {
    return <TypographyMuted>{children}</TypographyMuted>
}

type SectionValueProps = {
    label: string
    value: number
    percentage?: string
    className?: string
    formatter?: (value: number) => string
}

export const SectionValue = ({
    label,
    value,
    percentage,
    className,
    formatter,
}: SectionValueProps) => {
    return (
        <div>
            <SectionLabel>{label}</SectionLabel>
            <TypographyP
                className={cn(
                    'text-base text-accent-foreground',
                    value < 0 && 'text-destructive',
                    className
                )}
            >
                {formatter ? formatter(value) : value}{' '}
                {percentage && `(${percentage})`}
            </TypographyP>
        </div>
    )
}

type SectionTitleProps = {
    children: ReactNode
}

export const SectionTitle = ({ children }: SectionTitleProps) => {
    return (
        <TypographyP className="text-base font-semibold group-hover:text-muted-foreground">
            {children}
        </TypographyP>
    )
}

type SectionHeaderProps = {
    children: ReactNode
}

export const SectionHeader = ({ children }: SectionHeaderProps) => {
    const { isOpen, onToggle } = useSectionContext()

    return (
        <div>
            <div
                className="flex items-center justify-between pb-2 cursor-pointer group"
                onClick={onToggle}
            >
                {children}
                <ChevronDown
                    size={14}
                    className={cn(
                        'group-hover:text-muted-foreground ease duration-150',
                        isOpen ? '' : '-rotate-180'
                    )}
                />
            </div>
            <Separator />
        </div>
    )
}

type SectionContentProps = {
    children: ReactNode
    className?: string
}

export const SectionContent = ({
    children,
    className,
}: SectionContentProps) => {
    const { isOpen } = useSectionContext()

    return (
        isOpen && <div className={cn('space-y-2', className)}>{children}</div>
    )
}

type SectionProps = {
    children: ReactNode
}

export const Section = ({ children }: SectionProps) => {
    return (
        <SectionContextProvider>
            <div className="space-y-4">{children}</div>
        </SectionContextProvider>
    )
}

const SectionContext = createContext({
    isOpen: false,
    onToggle: () => {},
})

const SectionContextProvider = ({ children }: { children: ReactNode }) => {
    const { isOpen, onToggle } = useDisclosure(true)

    return (
        <SectionContext.Provider value={{ isOpen, onToggle }}>
            {children}
        </SectionContext.Provider>
    )
}

const useSectionContext = () => {
    const context = useContext(SectionContext)

    if (!context) {
        throw new Error(
            'useSectionContext must be used within a SectionContextProvider'
        )
    }

    return context
}
