import { Invoice } from '@/api/business/notasFiscais/type'
import ComponentLoader from '@/components/ComponentLoader'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { TypographyP } from '@/components/ui/typography'
import { useInvoicePurchaseOrderDetailQuery } from '@/queries/useInvoiceQuery'
import { getDateFromId } from '@/utils/date'
import { currencyFormat, formatCpfOrCnpj } from '@/utils/stringFormatter'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useMemo } from 'react'

export type InvoiceTable = TableData<Invoice>

const columnHelper = createColumnHelper<Invoice>()

type InvoicesSectionProps = {
    purchaseOrderId: string
}

const COLUMN_PROPS = {
    enableColumnFilter: false,
    meta: {
        enableMenu: false,
        enableColumnOrdering: false,
        header: {
            className: 'p-4 bg-accent',
        },
    },
}

const InvoicesContent = ({ purchaseOrderId }: InvoicesSectionProps) => {
    const { data: invoices = [] } = useInvoicePurchaseOrderDetailQuery({
        purchaseOrderId,
        enabled: true,
    })

    const memoData = useMemo(() => invoices, [invoices])
    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Cod empresa',
                size: 130,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                size: 150,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_DOCUMENTO_NF', {
                id: 'DD_DOCUMENTO_NF',
                header: 'Documento',
                size: 130,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_ITEM_NF', {
                id: 'DD_ITEM_NF',
                header: 'Item',
                size: 100,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_SERIE_NF', {
                id: 'DD_SERIE_NF',
                header: 'Série',
                size: 100,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_TES', {
                id: 'DD_TES',
                header: 'TES',
                size: 100,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_CODIGO_FISCAL', {
                id: 'DD_CODIGO_FISCAL',
                header: 'Código fiscal',
                size: 130,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 500,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 100,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('VL_UNITARIO', {
                id: 'VL_UNITARIO',
                header: 'Vlr unitário',
                size: 120,
                ...COLUMN_PROPS,
                meta: {
                    ...COLUMN_PROPS.meta,
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('VL_TOTAL', {
                id: 'VL_TOTAL',
                header: 'Vlr total',
                size: 150,
                ...COLUMN_PROPS,
                meta: {
                    ...COLUMN_PROPS.meta,
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('IPI', {
                id: 'IPI',
                header: 'IPI',
                size: 120,
                ...COLUMN_PROPS,
                meta: {
                    ...COLUMN_PROPS.meta,
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('ST', {
                id: 'ST',
                header: 'ST',
                size: 120,
                ...COLUMN_PROPS,
                meta: {
                    ...COLUMN_PROPS.meta,
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('ICMS', {
                id: 'ICMS',
                header: 'ICMS',
                size: 120,
                ...COLUMN_PROPS,
                meta: {
                    ...COLUMN_PROPS.meta,
                    cell: {
                        className: 'justify-end',
                        formatterFn: ({ value }) => currencyFormat(value),
                    },
                },
            }),
            columnHelper.accessor('DS_OBSERVACAO', {
                id: 'DS_OBSERVACAO',
                header: 'Observação',
                size: 400,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('NK_PEDIDO_DE_COMPRAS', {
                id: 'NK_PEDIDO_DE_COMPRAS',
                header: 'Pedido de compras',
                size: 170,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('NK_CENTRO_DE_CUSTO', {
                id: 'NK_CENTRO_DE_CUSTO',
                header: 'Número CC',
                size: 140,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('CENTRO_DE_CUSTO', {
                id: 'CENTRO_DE_CUSTO',
                header: 'Centro de custo',
                size: 300,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('NK_FORNECEDOR', {
                id: 'NK_FORNECEDOR',
                header: 'Cod Fornecedor',
                size: 150,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_RAZAO_SOCIAL', {
                id: 'DD_RAZAO_SOCIAL',
                header: 'Rz social',
                size: 400,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_NOME_FANTASIA', {
                id: 'DD_NOME_FANTASIA',
                header: 'Nm fantasia',
                size: 400,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_LOJA', {
                id: 'DD_LOJA',
                header: 'Loja',
                size: 100,
                ...COLUMN_PROPS,
            }),
            columnHelper.accessor('DD_CNPJ', {
                id: 'DD_CNPJ',
                header: 'CNPJ',
                size: 200,
                ...COLUMN_PROPS,
                meta: {
                    ...COLUMN_PROPS.meta,
                    cell: {
                        formatterFn: ({ value }) =>
                            value ? formatCpfOrCnpj(value) : '-',
                    },
                },
            }),
            columnHelper.accessor('DT_EMISSAO', {
                id: 'DT_EMISSAO',
                header: 'Dt emissão',
                size: 130,
                ...COLUMN_PROPS,
                meta: {
                    ...COLUMN_PROPS.meta,
                    cell: {
                        formatterFn: ({ value }) =>
                            value
                                ? format(getDateFromId(value), 'dd/MM/yyyy')
                                : '-',
                    },
                },
            }),
            columnHelper.accessor('DT_DIGITACAO', {
                id: 'DT_DIGITACAO',
                header: 'Dt digitação',
                size: 130,
                ...COLUMN_PROPS,
                meta: {
                    ...COLUMN_PROPS.meta,
                    cell: {
                        formatterFn: ({ value }) =>
                            value
                                ? format(getDateFromId(value), 'dd/MM/yyyy')
                                : '-',
                    },
                },
            }),
        ],
        []
    )

    return (
        <div className="space-y-4">
            <TypographyP className="font-semibold">Notas fiscais</TypographyP>
            <Table<InvoiceTable> data={memoData} columns={memoColumns} />
        </div>
    )
}

const InvoicesSection = ({ purchaseOrderId }: InvoicesSectionProps) => {
    const { isPending, isError, error } = useInvoicePurchaseOrderDetailQuery({
        purchaseOrderId,
        enabled: true,
    })

    return (
        <ComponentLoader
            error={error}
            isError={isError}
            isLoading={isPending}
            fallback={<div>Carregando...</div>}
        >
            <InvoicesContent purchaseOrderId={purchaseOrderId} />
        </ComponentLoader>
    )
}

export default InvoicesSection
