import { QuotationStatus } from '@/types/QuotationStatus'
import { FetchProductsAPIResponse } from '@/api/business/produtos/type'
import { FetchSuppliersAPIResponse } from '@/api/business/fornecedores/type'
import { PurchaseRequestFlat } from '../solicitacaoDeCompra/type'
import { Filter } from '@/types/Filters'
import { PaginationAPIProps, PaginationAPIResponse } from '@/types/Pagination'
import { NormalizedState } from '@/store/type'
import { Company } from '@/types/Companies'
import { Quotation, QuotationHeader, QuotationRounds } from '@/types/Quotation'
import { CurrencyDS } from '@/types/Currency'
import { QuotationSupplier, Supplier } from '@/types/Suppliers'
import { QuotationProduct } from '@/types/Products'
import { Approval } from '@/types/Approval'
// import { Attachment } from '@/types/Attachments'

export enum SavingType {
    PRECO = 'P',
    PRECO_MANTIDO = 'M',
    CUSTO_EVITADO = 'E',
    CONDICAO_PAGAMENTO = 'C',
    PERFORMANCE = 'F',
    NENHUM = 'N',
}

export enum SavingTypeMapper {
    'P' = 'Preço',
    'M' = 'Preço mantido',
    'E' = 'Custo Evitado',
    'C' = 'Condição de pagamento',
    'F' = 'Performance',
    'N' = 'Nenhum',
}

export type FetchQuotationHeadersProps = PaginationAPIProps<Quotation>
export type FetchQuotationHeadersResponse =
    PaginationAPIResponse<QuotationHeader>

export interface FetchQuotationFiltersResponse {
    FORNECEDORES: Pick<
        Supplier,
        'SK_FORNECEDOR' | 'DD_NOME_FANTASIA' | 'DD_RAZAO_SOCIAL'
    >[]
    PRODUTOS: Pick<
        PurchaseRequestFlat,
        | 'SK_PRODUTO'
        | 'SK_SOLICITACAO_DE_COMPRAS'
        | 'NK_PRODUTO'
        | 'NK_SOLICITACAO_DE_COMPRAS'
        | 'DS_PRODUTO'
    >[]
    EMPRESAS: Pick<
        Company,
        | 'SK_EMPRESA'
        | 'ABREVIATURA_EMPRESA'
        | 'COR_EMPRESA'
        | 'CNPJ'
        | 'DESCRICAO_EMPRESA'
    >[]
    STATUS: QuotationStatus[]
}

export type CreateQuotationProps =
    | {
          headers: {
              NM_COTACAO?: string
              DS_COTACAO?: string
              DT_EXPIRACAO: string
              DD_PUBLICADO: 0
              DD_SOLICITACAO_DE_COMPRAS: 0
          }
          attachments?: File[]
          suppliers?: { SK_FORNECEDOR: string }[]
          products?: { SK_PRODUTO: string; DD_QUANTIDADE: number }[]
          purchaseRequests?: null
          paymentConditions: {
              SK_CONDICAO_DE_PAGAMENTO: string
              DD_PREFERIDA: 0 | 1
          }[]
      }
    | {
          headers: {
              NM_COTACAO?: string
              DS_COTACAO?: string
              DT_EXPIRACAO: string
              DD_PUBLICADO: 0
              DD_SOLICITACAO_DE_COMPRAS: 1
          }
          attachments?: File[]
          suppliers?: { SK_FORNECEDOR: string }[]
          products?: null
          purchaseRequests?: { SK_SOLICITACAO_DE_COMPRAS: string }[]
          paymentConditions: {
              SK_CONDICAO_DE_PAGAMENTO: string
              DD_PREFERIDA: 0 | 1
          }[]
      }

export interface FetchQuotationAPIResponse
    extends Pick<
            FetchProductsAPIResponse,
            'SK_PRODUTO' | 'NK_PRODUTO' | 'DS_PRODUTO' | 'DD_UM' | 'DD_NCM'
        >,
        Pick<
            FetchSuppliersAPIResponse,
            | 'SK_FORNECEDOR'
            | 'DD_RAZAO_SOCIAL'
            | 'DD_NOME_FANTASIA'
            | 'DS_EMAIL'
            | 'DD_CNPJ'
            | 'DD_LOJA'
        >,
        Pick<
            PurchaseRequestFlat,
            'NK_SOLICITACAO_DE_COMPRAS' | 'SK_SOLICITACAO_DE_COMPRAS'
        >,
        Pick<Company, 'ABREVIATURA_EMPRESA' | 'COR_EMPRESA' | 'SK_EMPRESA'> {
    SK_COTACAO_X_RODADA: number
    SK_FORNECEDOR_X_RODADA: number
    SK_COTACAO_X_PRODUTO: number
    SK_COTACAO: number
    NM_COTACAO: string
    DS_COTACAO: string
    NUMERO_DA_COTACAO: string
    NUMERO_RODADA: string
    DT_EXPIRACAO: string
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    STATUS: QuotationStatus[]
    SK_USUARIO: number
    NOME_USUARIO: string
    EMAIL_USUARIO: string
    FAVORITADA: boolean
    DD_PUBLICADO: boolean
    DD_FINALIZADA: boolean
    DD_COTACAO_FINALIZADA: boolean
    DD_CANCELADA: boolean
    APROVACAO: Approval | null
    CREATED_AT: string
    UPDATED_AT: string
    D_E_L_E_T_: '*' | null
}

export interface FetchQuotationByIdAPIResponse {}

export interface FetchPaginatedQuotationsProps {
    page: number
    perPage: number
    filters: Filter<FetchQuotationAPIResponse>[]
}
export interface FetchPaginatedQuotationsResponse
    extends PaginationAPIResponse<FetchQuotationAPIResponse> {}

export type UpdateQuotationProps = Pick<
    FetchQuotationAPIResponse,
    'SK_COTACAO'
> &
    Partial<
        Pick<
            FetchQuotationAPIResponse,
            'NM_COTACAO' | 'DS_COTACAO' | 'DT_EXPIRACAO'
        >
    >

export interface FetchQuotationSuppliersResponse extends QuotationSupplier {}

export interface SuppliersToQuotation extends Supplier {
    DD_SUGESTAO: boolean
}
export interface FetchSuppliersToQuotationResponse
    extends PaginationAPIResponse<SuppliersToQuotation> {}
export interface FetchSuppliersToQuotationProps
    extends PaginationAPIProps<SuppliersToQuotation> {
    products?: {
        productNk: string
        companyId: string
    }[]
}

export interface AddSuppliersInQuotationProps {
    quotationId: number
    suppliersIds: Pick<FetchSuppliersAPIResponse, 'SK_FORNECEDOR'>[]
}
export interface AddSuppliersInQuotationResponse {
    message: string
}

export interface RemoveSuppliersFromQuotationProps {
    quotationId: number
    suppliersIds: Pick<FetchSuppliersAPIResponse, 'SK_FORNECEDOR'>[]
}

export interface RemoveSuppliersFromQuotationResponse {
    message: string
}

export type AddProductInQuotationProps =
    | {
          usePurchaseRequest: false
          quotationId: number
          products: Pick<
              FetchQuotationProductsResponse,
              'SK_PRODUTO' | 'DD_QUANTIDADE'
          >[]
      }
    | {
          usePurchaseRequest: true
          quotationId: number
          products: Pick<
              FetchQuotationProductsResponse,
              'SK_SOLICITACAO_DE_COMPRAS'
          >[]
      }

export interface FetchQuotationProductsProps {
    quotationId: number
}

export interface FetchQuotationSuppliersProps {
    quotationId: number
}

export interface UpdateSuppplierValuesProps {
    quotationId: number
    supplierId: string
    products: Partial<QuotationMapValues>[]
}

export interface FetchQuotationProductsResponse extends QuotationProduct {}

export interface AddProductInQuotationResponse {
    message: string
}

export type RemoveProductFromQuotationProps =
    | {
          usePurchaseRequest: false
          quotationId: number
          products: Pick<FetchQuotationProductsResponse, 'SK_PRODUTO'>[]
      }
    | {
          usePurchaseRequest: true
          quotationId: number
          products: Pick<
              FetchQuotationProductsResponse,
              'SK_SOLICITACAO_DE_COMPRAS'
          >[]
      }
export interface RemoveProductFromQuotationResponse {
    message: string
}

export interface UpdateQuotationProductProps {
    quotationId: number
    products: Partial<FetchProductsAPIResponse>[]
}

export interface UpdateQuotationProductResponse {
    message: string
}

export interface FetchQuotationMapProps {
    id: number
}

export interface QuotationMapValues
    extends Partial<CurrencyDS>,
        Pick<Company, 'SK_EMPRESA' | 'ABREVIATURA_EMPRESA' | 'COR_EMPRESA'> {
    SK_RESPOSTA_FORNECEDOR: number
    SK_RESPOSTA_SUPERIOR: number
    SK_FORNECEDOR: string
    SK_FORNECEDOR_X_RODADA: number
    SK_COTACAO_X_PRODUTO: number
    SK_COTACAO_X_RODADA: number
    PRECO: string | null
    PRECO_CONVERTIDO: number
    PRECO_IMPOSTO: number
    PRECO_COM_FRETE: number
    PRECO_TOTAL: number
    PRECO_TOTAL_IMPOSTO: number
    PRECO_TOTAL_COM_FRETE: number
    QTD_SOLICITADA: number
    QTD_DISPONIVEL: number
    SK_CONDICAO_DE_PAGAMENTO: string | null
    NK_CONDICAO_DE_PAGAMENTO: string | null
    DS_CONDICAO_DE_PAGAMENTO: string | null
    DS_CONDICAO_DE_PAGAMENTO_MANUAL: string | null
    SK_TRANSPORTADORA: string | null
    NM_TRANSPORTADORA: string | null
    NUMERO_RODADA: number
    IPI: string | null
    PIS: string | null
    COFINS: string | null
    ST: string | null
    FCP: string | null
    ICMS: string | null
    FRETE: string | null
    VL_FRETE: string | null
    PREVISAO_DE_ENTREGA: string | null
    OBSERVACAO: string | null
    VL_SPENDING: number
    VL_SAVING: number
    PERCENTUAL_SAVING: string
    SAVING_BASELINE: number | null
    SAVING_NEGOCIACAO: number | null
    SAVING_FINANCEIRO: number | null
    SAVING_BASELINE_PERCENTUAL: number | null
    SAVING_NEGOCIACAO_PERCENTUAL: number | null
    SAVING_FINANCEIRO_PERCENTUAL: number | null
    DD_SAVING: SavingType
    DD_RECUSADO: 0 | 1
    APROVACAO: Omit<Approval, 'COMPRADOR'> | null
    GRUPO: QuotationMapValues[]
}

export type QuotationMapProduct = Pick<
    FetchProductsAPIResponse,
    | 'SK_PRODUTO'
    | 'NK_PRODUTO'
    | 'DS_PRODUTO'
    | 'DD_UM'
    | 'DD_NCM'
    | 'ULTIMO_PRECO'
    | 'ULTIMO_PRECO_IPCA'
    | 'DT_ULTIMO_PRECO'
> &
    Pick<
        PurchaseRequestFlat,
        | 'SK_SOLICITACAO_DE_COMPRAS'
        | 'NK_SOLICITACAO_DE_COMPRAS'
        | 'NK_ITEM_SOLICITACAO_DE_COMPRAS'
    > & {
        SK_COTACAO_X_PRODUTO: number
        SK_EMPRESA: string
    }

export type QuotationMapProductNormalized = NormalizedState<QuotationMapProduct>

export type QuotationMapSupplier = Pick<
    FetchSuppliersAPIResponse,
    | 'SK_FORNECEDOR'
    | 'DD_RAZAO_SOCIAL'
    | 'DD_NOME_FANTASIA'
    | 'DS_EMAIL'
    | 'DD_CNPJ'
    | 'DD_LOJA'
    | 'DD_OBSERVACAO'
    | 'DD_ORIGEM_DADO'
> & {
    FORNECEDOR_RODADAS: number[]
    FORNECEDOR_COTACAO_RODADAS: number[]
    FORNECEDOR_PUBLICADO: 0 | 1
    FORNECEDOR_DECLINADO: 0 | 1
    DD_VENCEDOR: 0 | 1
    MOTIVO_DECLINIO: string
    CREATED_AT: string
}

export type QuotationMapSupplierNormalized =
    NormalizedState<QuotationMapSupplier>
export interface FetchQuotationMapResponse {
    PRODUTOS: QuotationMapProductNormalized
    FORNECEDORES: QuotationMapSupplierNormalized
    EMPRESAS: Company[]
    VALORES: QuotationMapValues[]
    RODADAS: (QuotationRounds & {
        SK_FORNECEDOR_X_RODADA: number
        FORNECEDOR_RODADAS: number[]
    })[]
}

export interface ValidatePurchaseOrderProps {
    quotations: {
        quotationProductId: number
        roundSupplierId: number
        supplierResponseId: number
        savingType: SavingType
        spending: number
        saving: number
        savingPercentage: number
    }[]
}
export interface GeneratePurchaseOrderProps {
    quotations: { supplierResponseId: number }[]
}

export interface CreateNewRoundProps {
    quotationId: number
    expirationDate: string
    suppliers: {
        SK_FORNECEDOR: string
    }[]
}

export interface ChangeRoundDateProps {
    quotationId: number
    date: string
}

export interface FinalizeRoundProps {
    quotationId: number
}

export interface DeleteQuotationProps {
    id: number
    forceDelete?: boolean
}

export interface AddPaymentConditionProps {
    quotationId: number
    paymentConditions: {
        SK_CONDICAO_DE_PAGAMENTO: string
        DD_PREFERIDA: 0 | 1
    }[]
}

export interface UpdatePaymentConditionPreferenceProps {
    quotationId: number
    paymentConditionId: string
    preference: 0 | 1
}

export interface RemovePaymentConditionProps {
    quotationId: number
    paymentConditions: {
        SK_CONDICAO_DE_PAGAMENTO: string
    }[]
}

export interface PublishSupplierProps {
    quotationId: number
    supplierId: string
}

export type GeneratePurchaseOrderSucess = {
    SK_RESPOSTA_FORNECEDOR: number
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    SK_PRODUTO: string
    DS_PRODUTO: string
    QTD_SOLICITADA: string
    SK_FORNECEDOR: string
    DD_NOME_FANTASIA: string
    DD_RAZAO_SOCIAL: string
    SK_SOLICITACAO_DE_COMPRAS: string
    NK_SOLICITACAO_DE_COMPRAS: string
    NK_ITEM_SOLICITACAO_DE_COMPRAS: string
    NK_ITEM_PEDIDO_DE_COMPRAS: string
    SK_PEDIDO_DE_COMPRAS: string
    OBSERVACAO: string
    NK_FORNECEDOR: string
    DT_EMISSAO: number
    NK_PEDIDO: string
    created: true
}

export type GeneratePurchaseOrderError = {
    SK_RESPOSTA_FORNECEDOR: number
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    SK_PRODUTO: string
    DS_PRODUTO: string
    QTD_SOLICITADA: number
    SK_FORNECEDOR: string
    DD_NOME_FANTASIA: string
    DD_RAZAO_SOCIAL: string
    SK_SOLICITACAO_DE_COMPRAS: string
    NK_SOLICITACAO_DE_COMPRAS: string
    NK_ITEM_SOLICITACAO_DE_COMPRAS: string
    NK_FORNECEDOR: string
    ERRO: string
    created: false
}

export type GeneratePurchaseOrderResponse =
    | GeneratePurchaseOrderSucess
    | GeneratePurchaseOrderError

type ValidateErrors = { message: string }

type RateioErrors = {
    ERRO: boolean
    MENSAGEM: string
    NK_CONTA_ORCAMENTARIA: string
    NK_CENTRO_DE_CUSTO: string
    VALOR: number
    SALDO: number
}

export type ValidatePurchaseOrderItems = {
    SK_SOLICITACAO_DE_COMPRAS: string
    NK_SOLICITACAO_DE_COMPRAS: string
    NK_ITEM_SOLICITACAO_DE_COMPRAS: string
    SK_PRODUTO: string
    NK_PRODUTO: string
    DS_PRODUTO: string
    ERROS: ValidateErrors[]
    ERROS_RATEIO: RateioErrors[]
}

export type ValidatePurchaseOrderResponse = {
    SK_RESPOSTA_FORNECEDOR: number
    ORDEM_PEDIDO: string
    SK_EMPRESA: string
    ABREVIATURA_EMPRESA: string
    SK_FORNECEDOR: string
    DD_NOME_FANTASIA: string
    DD_RAZAO_SOCIAL: string
    NK_FORNECEDOR: string
    CNPJ: string
    NK_CONDICAO_DE_PAGAMENTO: string
    FRETE: string
    ITENS: ValidatePurchaseOrderItems[]
    ERROS: ValidateErrors[]
    ERRO_CABECALHO: boolean
    ERRO_ITEM: boolean
}

export type AddShippingCompanyProps = {
    quotationId: number
    shippingCompanyId: string
    supplierId: string
    companyId: string
    freightValue: string
}

export type RemoveShippingCompanyProps = {
    quotationId: number
    supplierId: string
    companyId: string
}

export interface AddProductRowProps {
    SK_RESPOSTA_FORNECEDOR: number
    PRODUTOS: {
        PRECO: string
        QTD_DISPONIVEL: number
        PREVISAO_DE_ENTREGA: string | null
        OBSERVACAO: string | null
    }[]
}

export interface RemoveProductGroupProps {
    SK_RESPOSTA_FORNECEDOR: number
}
