import { cn } from '@/lib/utils'
import {
    Select as CustomSelect,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../ui/select'

interface SelectProps {
    className?: string
    placeholder?: string
    disabled?: boolean
    options: {
        value: string
        label: string
    }[]
    value: string
    onChange: (value: string) => void
}

const Select = ({
    placeholder,
    options,
    disabled,
    value,
    className,
    onChange,
}: SelectProps) => {
    return (
        <CustomSelect
            disabled={disabled}
            onValueChange={onChange}
            value={value}
        >
            <SelectTrigger className={cn('text-muted-foreground', className)}>
                <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent className="max-h-[300px] overflow-y-auto">
                {options.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                        {option.label}
                    </SelectItem>
                ))}
            </SelectContent>
        </CustomSelect>
    )
}

export default Select
