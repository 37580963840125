export enum QuotationStatus {
    'TODOS' = 'TODOS',
    'EM ABERTO' = 'EM ABERTO',
    'FINALIZADA' = 'FINALIZADA',
    'RASCUNHO' = 'RASCUNHO',
    'EXCLUIDA' = 'EXCLUIDA',
    'SEM FORNECEDOR' = 'SEM FORNECEDOR',
    'SEM PRODUTO' = 'SEM PRODUTO',
    'FINALIZADA PARCIALMENTE' = 'FINALIZADA PARCIALMENTE',
    'TOTALMENTE ATENDIDA' = 'TOTALMENTE ATENDIDA',
    'CANCELADA' = 'CANCELADA',
    'EM_APROVACAO' = 'EM APROVACAO',
    'APROVADA' = 'APROVADA',
    'RECUSADA' = 'RECUSADA',
    'PEDIDO GRAVADO' = 'PEDIDO GRAVADO',
    'NOVA_APROVACAO' = 'NOVA APROVAÇÃO',
}

export enum QuotationStatusColorScheme {
    'EM ABERTO' = 'text-emerald-500',
    'FINALIZADA' = 'text-sky-500',
    'FINALIZADA PARCIALMENTE' = 'text-slate-400',
    'RASCUNHO' = 'text-slate-500',
    'EXCLUIDA' = 'text-neutral-500',
    'SEM FORNECEDOR' = 'text-yellow-600',
    'SEM PRODUTO' = 'text-yellow-600',
    'TOTALMENTE ATENDIDA' = 'text-green-500',
    'CANCELADA' = 'text-red-500',
    'EM_APROVACAO' = 'text-amber-500',
    'APROVADA' = 'text-blue-500',
    'RECUSADA' = 'text-red-500',
    'PEDIDO GRAVADO' = 'text-sky-500',
    'NOVA_APROVACAO' = 'text-amber-500',
}
