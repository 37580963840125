import ProductsSection from './components/ProductsSection'
import SuppliersSection from './components/SuppliersSection'
import StatusBar from './components/StatusBar'
import QuotationDetailsSection from './components/QuotationDetailsSection'
import PaymentConditionsSection from './components/PaymentConditionsSection'
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from '@/components/ui/resizable'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import ActionsMenuBar from './components/ActionsMenuBar'
import { Paperclip } from 'lucide-react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import QuotationMapSection from './components/QuotationMapSection'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import AttachmentsSection from './components/AttachmentsSection'
import { TypographyMuted } from '@/components/ui/typography'

type TabValue =
    | 'map'
    | 'suppliers'
    | 'products'
    | 'payment-conditions'
    | 'attachments'

const tabValues: TabValue[] = [
    'map',
    'suppliers',
    'products',
    'payment-conditions',
    'attachments',
]

const tabs: {
    label: string
    value: TabValue
    Icon?: React.ElementType
}[] = [
    { label: 'Mapa', value: 'map' },
    { label: 'Fornecedores', value: 'suppliers' },
    { label: 'Solic. de compras', value: 'products' },
    { label: 'Condições de pagamento', value: 'payment-conditions' },
    { label: 'Anexos', value: 'attachments', Icon: Paperclip },
]

type RenderProps = {
    mapDisabled: boolean
}

const render = ({
    mapDisabled,
}: RenderProps): Record<TabValue, React.ReactNode> => ({
    suppliers: (
        <TabsContent value="suppliers">
            <SuppliersSection />
        </TabsContent>
    ),
    products: (
        <TabsContent value="products">
            <ProductsSection />
        </TabsContent>
    ),
    'payment-conditions': (
        <TabsContent value="payment-conditions">
            <PaymentConditionsSection />
        </TabsContent>
    ),
    attachments: (
        <TabsContent value="attachments">
            <AttachmentsSection />
        </TabsContent>
    ),
    map: mapDisabled ? (
        <div className="h-[150px] flex items-center justify-center">
            <TypographyMuted>Mapa indisponível</TypographyMuted>
        </div>
    ) : (
        <TabsContent value="map">
            <QuotationMapSection />
        </TabsContent>
    ),
})

const QuotationDetail = () => {
    const { id } = useParams()
    const { search } = useLocation()

    const [params, setParams] = useSearchParams(search)
    const [activeTab, setActiveTab] = useState<TabValue>()

    const { data } = useQuotationByIdQuery({ id: Number(id) })

    const onTabChange = (value: TabValue) => {
        setParams({ tab: value })
    }

    useEffect(() => {
        const value = params.get('tab') as TabValue

        setActiveTab(tabValues.includes(value) ? value : tabValues[0])
    }, [params])

    return (
        <>
            <StatusBar />
            <ResizablePanelGroup
                className="flex-1 overflow-hidden"
                direction="horizontal"
            >
                <ResizablePanel
                    className="space-y-2"
                    defaultSize={18}
                    minSize={10}
                >
                    <QuotationDetailsSection />
                </ResizablePanel>
                <ResizableHandle />
                <ResizablePanel
                    defaultSize={82}
                    style={{
                        overflow: 'auto',
                    }}
                    className="p-4 space-y-4 overflow-auto"
                >
                    <Tabs
                        value={activeTab}
                        onValueChange={(value) =>
                            onTabChange(value as TabValue)
                        }
                    >
                        <div className="flex flex-wrap justify-between gap-4">
                            <TabsList>
                                {tabs.map((tab) => {
                                    return (
                                        <TabsTrigger
                                            value={tab.value}
                                            key={tab.value}
                                        >
                                            {tab.Icon && <tab.Icon size={14} />}
                                            {tab.label}
                                        </TabsTrigger>
                                    )
                                })}
                            </TabsList>
                            <ActionsMenuBar />
                        </div>
                        {activeTab &&
                            render({
                                mapDisabled: !data?.DD_PUBLICADO,
                            })[activeTab]}
                    </Tabs>
                    {/* <Separator /> */}
                </ResizablePanel>
            </ResizablePanelGroup>
        </>
    )
}
export default QuotationDetail
