import { PurchaseRequestStatus } from '@/api/business/solicitacaoDeCompra/type'

export default (prioritiy: PurchaseRequestStatus) => {
    const baseName = '--chart-status-'

    const variable: Record<PurchaseRequestStatus, string> = {
        INDEFINIDO: `${baseName}indefinido`,
        BLOQUEADA: `${baseName}bloqueada`,
        'ELIMINADA POR RESÍDUO': `${baseName}eliminada-por-residuo`,
        'EM COTACAO': `${baseName}em-cotacao`,
        'ERRO PEDIDO': `${baseName}erro-pedido`,
        'GESTÃO DE CONTRATOS': `${baseName}gestao-de-contratos`,
        PENDENTE: `${baseName}pendente`,
        REJEITADA: `${baseName}rejeitada`,
        'SOLICITAÇÃO DE PRODUTO IMPORTADO': `${baseName}produto-importado`,
        'TOTALMENTE ATENDIDA': `${baseName}totalmente-atendida`,
    }

    return variable[prioritiy]
}
