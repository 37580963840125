import TopMessage from '@/components/TopMessage'
import { useQuotationMapQuery } from '@/queries/useQuotatioMapQuery'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import { useParams } from 'react-router-dom'
import QuotationMapTable from '../QuotationMapTable'
import QuotationMapSummary from '../QuotationMapSummary'

const QuotationMapContent = () => {
    const { id } = useParams()

    const { data: quotationDetail, isError: isQuotationError } =
        useQuotationByIdQuery({ id: Number(id) })

    const {
        data: quotationMap,
        isFetching: isQuotationMapFetching,
        isError: isQuotationMapError,
    } = useQuotationMapQuery({ id: Number(id) })

    if (isQuotationError || isQuotationMapError)
        return 'Erro ao renderizar o mapa de cotações'

    if (!quotationDetail || !quotationMap) return 'Carregando...'

    return (
        <div className="flex flex-col w-full h-full">
            {isQuotationMapFetching && (
                <TopMessage variant="loading" text="Atualizando mapa..." />
            )}
            <div className="flex h-screen gap-4">
                <section className="flex flex-col flex-1 py-2 space-y-4 overflow-hidden">
                    <QuotationMapTable />
                </section>
                <QuotationMapSummary />
            </div>
        </div>
    )
}

export default QuotationMapContent
