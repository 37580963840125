import { Button } from '@/components/ui/button'
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from '@/components/ui/drawer'
import { Eye } from 'lucide-react'
import LeadTimeDashboard from '@/components/LeadTimeDashboard'
import DetailsSection from './components/DetailsSection'
import { Invoice } from '@/api/business/notasFiscais/type'

type LeadTimeDrawerProps = {
    invoice: Invoice
}

const LeadTimeDrawer = ({ invoice }: LeadTimeDrawerProps) => {
    return (
        <Drawer>
            <DrawerTrigger asChild>
                <Button variant="ghost" size="icon" className="mx-auto">
                    <Eye />
                </Button>
            </DrawerTrigger>
            <DrawerContent className="h-full  max-h-[90vh]">
                <div className="flex h-full gap-4 p-6">
                    <div className="flex flex-col h-full space-y-1 rounded-md shrink-0 bg-accent">
                        <DrawerHeader className="pb-4">
                            <DrawerTitle>
                                Nota fiscal - #{invoice.DD_DOCUMENTO_NF}
                            </DrawerTitle>
                            <DrawerDescription>
                                Detalhamento e leadtime da nota fiscal{' '}
                                {invoice.DD_DOCUMENTO_NF}
                            </DrawerDescription>
                        </DrawerHeader>
                        <DetailsSection invoice={invoice} />
                    </div>
                    <LeadTimeDashboard
                        purchaseOrderId={invoice.SK_PEDIDO_DE_COMPRAS}
                    />
                </div>
            </DrawerContent>
        </Drawer>
    )
}
export default LeadTimeDrawer
