import ButtonWithTooltip from '@/components/ButtonWithTooltip'
import CreateLayoutDialog from '@/components/CreateLayoutDialog'
import LayoutDialog from '@/components/LayoutsDialog'
import { Separator } from '@/components/ui/separator'
import { TableIds } from '@/config/table'
import useDisclosure from '@/hooks/useDisclosure'
import { ArrowDownToLine, FileUp, PlusCircle, RefreshCcw } from 'lucide-react'
import { ReactNode } from 'react'

interface DadosMestreHeaderActionsProps {
    CustomButtons?: ReactNode
    layout?: boolean
    tableId: TableIds
    onAdd?: () => void
    onDownload?: () => void
    onRefech?: () => void
    onBulk?: () => void
}

const DadosMestreHeaderActions = ({
    CustomButtons,
    layout,
    tableId,
    onAdd,
    onBulk,
    onDownload,
    onRefech,
}: DadosMestreHeaderActionsProps) => {
    const {
        isOpen: isLayoutsDialogOpen,
        onOpenChange: onLayoutsDialogOpenChange,
    } = useDisclosure()
    const {
        isOpen: isCreateLayoutsDialogOpen,
        onOpenChange: onCreateLayoutsDialogOpenChange,
    } = useDisclosure()

    return (
        <>
            {CustomButtons}
            {onAdd && (
                <>
                    <ButtonWithTooltip
                        className="flex items-center justify-center w-auto gap-2 px-4 rounded-md"
                        tooltipContent={<p>Adicionar</p>}
                        onClick={onAdd}
                        size="default"
                    >
                        <PlusCircle size={18} />
                        Novo
                    </ButtonWithTooltip>
                    <Separator orientation="vertical" className="w-[1px] h-5" />
                </>
            )}
            {onRefech && (
                <>
                    <ButtonWithTooltip
                        tooltipContent={<p>Atualizar</p>}
                        onClick={onRefech}
                        variant="ghost"
                    >
                        <RefreshCcw size={18} />
                    </ButtonWithTooltip>
                    <Separator orientation="vertical" className="w-[1px] h-5" />
                </>
            )}

            {onBulk && (
                <>
                    <ButtonWithTooltip
                        tooltipContent={<p>Carga</p>}
                        onClick={onBulk}
                        variant="ghost"
                    >
                        <FileUp size={18} />
                    </ButtonWithTooltip>
                    <Separator orientation="vertical" className="w-[1px] h-5" />
                </>
            )}
            {onDownload && (
                <>
                    <ButtonWithTooltip
                        tooltipContent={<p>Download</p>}
                        onClick={onDownload}
                        variant="ghost"
                    >
                        <ArrowDownToLine size={18} />
                    </ButtonWithTooltip>
                    <Separator orientation="vertical" className="w-[1px] h-5" />
                </>
            )}
            {layout && (
                <>
                    <LayoutDialog
                        isOpen={isLayoutsDialogOpen}
                        onCreateDialogOpenChange={
                            onCreateLayoutsDialogOpenChange
                        }
                        onOpenChange={onLayoutsDialogOpenChange}
                        tableId={tableId}
                    />
                    <CreateLayoutDialog
                        isOpen={isCreateLayoutsDialogOpen}
                        onOpenChange={onCreateLayoutsDialogOpenChange}
                        tableId={tableId}
                    />
                </>
            )}
        </>
    )
}

export default DadosMestreHeaderActions
