import { useDateIntervalContext } from '@/context/DateIntervalContextProvider'
import { DatePicker } from '../DatePicker'
import { TypographyMuted } from '../ui/typography'

const DateInterval = () => {
    const { endDate, startDate, setDate } = useDateIntervalContext()

    return (
        <div className="flex items-center gap-4">
            <TypographyMuted>De</TypographyMuted>
            <DatePicker
                className="w-[170px]"
                date={startDate}
                setDate={(date) => date && setDate(date, 'startDate')}
            />
            <TypographyMuted>Até</TypographyMuted>
            <DatePicker
                className="w-[170px]"
                date={endDate}
                setDate={(date) => date && setDate(date, 'endDate')}
            />
        </div>
    )
}

export default DateInterval
