import { isAxiosError } from '@/api/business'
import {
    Intervals,
    Priority,
    PurchaseRequestFlat,
    INTERVAL_CLASSNAME,
} from '@/api/business/solicitacaoDeCompra/type'
import PurchaseRequestItemsDialog from '@/components/PurchaseRequestItemsDialog'
import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import useDisclosure from '@/hooks/useDisclosure'
import { cn } from '@/lib/utils'
import { useQuotationPurchaseRequestListQuery } from '@/queries/usePurchaseRequestQuery'
import { useBaseStore } from '@/store'
import { getFiltersSelector } from '@/store/quotationPurchaseRequestSlice'
import { Store } from '@/store/type'
import { Filter, Operators } from '@/types/Filters'
import { getDateFromId, getIdFromDate } from '@/utils/date'
import { ColumnPinningState, createColumnHelper } from '@tanstack/react-table'
import { format, isValid } from 'date-fns'
import { AlertTriangle, Eye } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ActionBar from '../ActionBar'
import TableHeader from '../TableHeader'
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import { useTable } from '@/hooks/useTable'
import { currencyFormat } from '@/utils/stringFormatter'
import getCssVariable from '@/pages/PurchaseRequestPage/components/StatusChart/util/getCssVariable'

export type PurchaseRequestTable = TableData<PurchaseRequestFlat>

const columnHelper = createColumnHelper<PurchaseRequestFlat>()

const PAGE_SIZE = 25

export interface And {
    CAMPO: keyof PurchaseRequestFlat
    VALOR: string
    OPERADOR: Operators
}

const actionsSelector = (state: Store) => ({
    onSelectItems: state.quotationPurchaseRequestSlice.actions.onSelectItems,
    onDeselectAllItems:
        state.quotationPurchaseRequestSlice.actions.onDeselectAllItems,
    onAddColumnFilter:
        state.quotationPurchaseRequestSlice.actions.onAddColumnFilter,
})

const stateSelector = (state: Store) => ({
    rowSelection: state.quotationPurchaseRequestSlice.state.rowSelection,
})

const SectionPurchaseRequestList = () => {
    const { state } = useLocation()
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [filter, setFilter] = useState<Filter<PurchaseRequestFlat>[]>([])
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
        left: ['Ações', 'Seleção'],
    })

    const navigate = useNavigate()

    const {
        pageIndex,
        columnFilters,
        onPaginationChange,
        onColumnFiltersChange,
    } = useTable()

    const { rowSelection } = useBaseStore(stateSelector)

    const { onSelectItems, onDeselectAllItems, onAddColumnFilter } =
        useBaseStore(actionsSelector)

    const filters = useBaseStore(getFiltersSelector)

    const { data, isPending, isFetching, isError, error } =
        useQuotationPurchaseRequestListQuery({
            currentPage: pageIndex,
            perPage: PAGE_SIZE,
            filters,
            enabled: true,
        })

    const dataMemo = useMemo(() => data?.DADOS || [], [data])

    const columnsMemo = useMemo(
        () => [
            columnHelper.display({
                id: 'Ações',
                cell: ({ row }) => {
                    return (
                        <div className="flex items-center justify-center">
                            <button
                                onClick={() => {
                                    setFilter([
                                        {
                                            AND: [
                                                {
                                                    field: 'NK_SOLICITACAO_DE_COMPRAS',
                                                    operator: Operators.equals,
                                                    value: row.original
                                                        .NK_SOLICITACAO_DE_COMPRAS,
                                                },
                                            ],
                                        },
                                    ])
                                    onOpen()
                                }}
                            >
                                <Eye size={14} />
                            </button>
                        </div>
                    )
                },
                meta: {
                    cell: {
                        className: 'justify-center',
                    },
                    enableColumnOrdering: false,
                },
                size: 50,
            }),
            columnHelper.accessor('DD_STATUS', {
                id: 'DD_STATUS',
                header: 'Status',
                cell: ({ row }) => {
                    const { DD_STATUS } = row.original

                    return DD_STATUS ? (
                        <span
                            style={{
                                color: `hsl(var(${getCssVariable(DD_STATUS)}))`,
                            }}
                            className={cn('px-2 truncate')}
                        >
                            {DD_STATUS}
                        </span>
                    ) : null
                },
                size: 120,
            }),
            columnHelper.accessor('NK_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_SOLICITACAO_DE_COMPRAS',
                header: 'Número da SC',
                size: 100,
            }),
            columnHelper.accessor('NUMERO_DA_COTACAO', {
                id: 'NUMERO_DA_COTACAO',
                header: 'Número da cotação',
                cell: ({ getValue, row }) => {
                    const { SK_COTACAO } = row.original
                    return (
                        <span
                            className="text-blue-500 truncate cursor-pointer hover:underline"
                            onClick={() => navigate(`/cotacoes/${SK_COTACAO}`)}
                        >
                            {getValue()}
                        </span>
                    )
                },
                size: 130,
            }),
            columnHelper.accessor('SK_EMPRESA', {
                id: 'SK_EMPRESA',
                header: 'Código empresa',
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            className="truncate"
                            style={{
                                color: COR_EMPRESA || undefined,
                            }}
                        >
                            {getValue()}
                        </span>
                    )
                },
                size: 100,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                cell: ({ getValue, row }) => {
                    const { COR_EMPRESA } = row.original

                    return (
                        <span
                            className="truncate"
                            style={{
                                color: COR_EMPRESA || undefined,
                            }}
                        >
                            {getValue()}
                        </span>
                    )
                },
                size: 150,
            }),
            columnHelper.accessor('NK_ITEM_SOLICITACAO_DE_COMPRAS', {
                id: 'NK_ITEM_SOLICITACAO_DE_COMPRAS',
                header: 'Item SC',
                size: 100,
            }),
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Código produto',
                size: 120,
            }),
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 400,
            }),
            columnHelper.accessor('DD_UM', {
                id: 'DD_UM',
                header: 'UM',
                size: 100,
            }),
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 100,
            }),
            columnHelper.accessor('DS_GRUPO_DE_PRODUTOS', {
                id: 'DS_GRUPO_DE_PRODUTOS',
                header: 'Grupo de produto',
                size: 200,
            }),
            columnHelper.accessor('DD_URGENCIA', {
                id: 'DD_URGENCIA',
                header: 'Prioridade',
                cell: ({ getValue }) => {
                    const priority = getValue() as Priority
                    return priority === 'Urgente' ? (
                        <span className="flex items-center gap-1.5">
                            <AlertTriangle
                                size={12}
                                className="text-orange-500"
                            />
                            {priority}
                        </span>
                    ) : (
                        priority
                    )
                },
                size: 110,
            }),
            columnHelper.accessor(
                ({ DD_RATEIO }) => (DD_RATEIO === 1 ? 'Sim' : 'Não'),
                {
                    id: 'DD_RATEIO',
                    header: 'Rateio',
                    size: 110,
                }
            ),
            columnHelper.accessor('DS_NOME_COMPRADOR', {
                id: 'DS_NOME_COMPRADOR',
                header: 'Comprador',
                size: 200,
            }),
            columnHelper.accessor('NK_CENTRO_DE_CUSTO', {
                id: 'NK_CENTRO_DE_CUSTO',
                header: 'Código centro de custo',
                size: 150,
            }),
            columnHelper.accessor('CENTRO_DE_CUSTO', {
                id: 'CENTRO_DE_CUSTO',
                header: 'Centro de custo',
                size: 200,
            }),
            columnHelper.accessor('NK_CONTA_ORCAMENTARIA', {
                id: 'NK_CONTA_ORCAMENTARIA',
                header: 'Código conta orçamentária',
                size: 100,
            }),
            columnHelper.accessor('CONTA_ORCAMENTARIA', {
                id: 'CONTA_ORCAMENTARIA',
                header: 'Conta orçamentária',
                size: 300,
            }),
            columnHelper.accessor('QTD_SOLICITADA', {
                id: 'QTD_SOLICITADA',
                header: 'Qtde solicitada',
                size: 100,
            }),
            columnHelper.accessor('QTD_PEDIDA', {
                id: 'QTD_PEDIDA',
                header: 'Qtde pedida',
                size: 100,
            }),
            columnHelper.accessor(
                ({ ULTIMO_PRECO }) =>
                    ULTIMO_PRECO
                        ? currencyFormat(Number(ULTIMO_PRECO) || 0)
                        : '-',
                {
                    id: 'ULTIMO_PRECO',
                    header: 'Último preço',
                    size: 130,
                    meta: {
                        cell: {
                            className: 'justify-end',
                        },
                    },
                }
            ),
            columnHelper.accessor(
                ({ DD_BLOQUEADO }) => (DD_BLOQUEADO ? 'Sim' : 'Não'),
                {
                    id: 'DD_BLOQUEADO',
                    header: 'Bloqueado',
                    cell: ({ getValue }) => {
                        return (
                            <span
                                className={cn(
                                    'px-2',
                                    getValue() === 'Sim'
                                        ? 'bg-red-50 text-red-500'
                                        : 'bg-emerald-50 text-emerald-500'
                                )}
                            >
                                {getValue()}
                            </span>
                        )
                    },
                    meta: {
                        cell: {
                            className: 'justify-center',
                        },
                    },
                    size: 120,
                }
            ),
            columnHelper.accessor('DD_SOLICITANTE', {
                id: 'DD_SOLICITANTE',
                header: 'Solicitante',
                size: 130,
            }),
            columnHelper.accessor('DD_OBSERVACAO', {
                id: 'DD_OBSERVACAO',
                header: 'Observação',
                size: 300,
            }),
            columnHelper.accessor('DD_APROVADOR', {
                id: 'DD_APROVADOR',
                header: 'Aprovador',
                size: 300,
            }),
            columnHelper.accessor(
                ({ DT_PRAZO }) =>
                    DT_PRAZO
                        ? format(getDateFromId(Number(DT_PRAZO)), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_PRAZO',
                    header: 'Prazo',
                    size: 150,
                }
            ),
            columnHelper.accessor('INTERVALO_PRAZO', {
                id: 'INTERVALO_PRAZO',
                header: 'Intervalo',
                cell: ({ getValue }) => {
                    const interval = getValue() as Intervals
                    return (
                        <span
                            className={cn(
                                'truncate',
                                INTERVAL_CLASSNAME[interval]
                            )}
                        >
                            {interval}
                        </span>
                    )
                },
                size: 220,
                meta: {
                    cell: {
                        className: 'justify-center',
                    },
                },
            }),
            columnHelper.accessor('ATRASO', {
                id: 'ATRASO',
                header: 'Atraso',
                size: 100,
            }),
            columnHelper.accessor(
                ({ DT_APROVACAO }) =>
                    DT_APROVACAO
                        ? format(getDateFromId(DT_APROVACAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_APROVACAO',
                    header: 'Data aprovação',
                    size: 150,
                }
            ),
            columnHelper.accessor(
                ({ DT_EMISSAO }) =>
                    DT_EMISSAO
                        ? format(getDateFromId(DT_EMISSAO), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_EMISSAO',
                    header: 'Data emissão',
                    size: 150,
                }
            ),
            columnHelper.accessor(
                ({ DT_NECESSIDADE }) =>
                    DT_NECESSIDADE
                        ? format(getDateFromId(DT_NECESSIDADE), 'dd/MM/yyyy')
                        : '',
                {
                    id: 'DT_NECESSIDADE',
                    header: 'Data necessidade',
                    size: 150,
                }
            ),
        ],
        []
    )

    useEffect(() => {
        if (state?.redirected) {
            onDeselectAllItems()
        }
    }, [])

    return (
        <>
            <Table<PurchaseRequestTable>
                data={dataMemo}
                isLoading={isPending}
                isFetching={isFetching}
                isError={isError}
                errorMessage={
                    isError && isAxiosError(error)
                        ? error?.response?.data.message
                        : 'Erro ao carregar dados'
                }
                tableHeader={<TableHeader />}
                tableActions={<ActionBar />}
                tableState={{
                    columnPinning,
                    rowSelection,
                    columnFilters,
                    pagination: {
                        pageIndex: pageIndex,
                        pageSize: PAGE_SIZE,
                    },
                }}
                pagination={{
                    pageSize: PAGE_SIZE,
                    totalPages: data?.TOTAL_DE_PAGINAS || 0,
                    totalItems: data?.TOTAL || 0,
                }}
                onColumnPinningStateChange={setColumnPinning}
                columns={columnsMemo}
                onRowSelectionChange={(rowSelection) => {
                    onSelectItems(
                        rowSelection,
                        dataMemo.filter((item) =>
                            Object.keys(rowSelection).includes(
                                item.SK_SOLICITACAO_DE_COMPRAS
                            )
                        )
                    )
                }}
                enableRowSelection={(row) => row.original.GERA_COTACAO}
                getRowId={(row) => row.SK_SOLICITACAO_DE_COMPRAS}
                onColumnFiltersChange={(filters) => {
                    if (filters.length > 0) {
                        const filter: Filter<PurchaseRequestFlat>[] = [
                            {
                                AND: filters.map((filter) => {
                                    if (filter.id.includes('DT_')) {
                                        const dateString = (
                                            filter.value as string
                                        )
                                            .split('/')
                                            .map(Number)

                                        const date = new Date(
                                            dateString[2],
                                            dateString[1] - 1,
                                            dateString[0]
                                        )

                                        const formattedDate = isValid(date)
                                            ? getIdFromDate(date).join('')
                                            : (filter.value as string)

                                        return {
                                            field: filter.id as keyof PurchaseRequestFlat,
                                            value: formattedDate,
                                            operator: Operators.equals,
                                        }
                                    }

                                    switch (filter.id) {
                                        case 'DD_RATEIO':
                                            return {
                                                field: filter.id as keyof PurchaseRequestFlat,
                                                value:
                                                    (
                                                        filter.value as string
                                                    ).toLowerCase() === 'sim'
                                                        ? 1
                                                        : 0,
                                                operator: Operators.like,
                                            }
                                        default:
                                            return {
                                                field: filter.id as keyof PurchaseRequestFlat,
                                                value: filter.value as string,
                                                operator: Operators.like,
                                            }
                                    }
                                }),
                            },
                        ]

                        onAddColumnFilter(filter)
                    } else {
                        onAddColumnFilter([])
                    }
                    onColumnFiltersChange(filters)
                }}
                onPaginationChange={onPaginationChange}
                defaultColumn={{
                    cell: ({ getValue }) => (
                        <DefaultColumn>{getValue() as string}</DefaultColumn>
                    ),
                }}
            />
            <PurchaseRequestItemsDialog
                isOpen={isOpen}
                onClose={onClose}
                rowSelection={{
                    onSelectPurchaseRequestItems: onSelectItems,
                    selectedRows: rowSelection,
                }}
                filters={filter}
            />
        </>
    )
}

export default SectionPurchaseRequestList
