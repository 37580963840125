import { TypographyLarge } from '@/components/ui/typography'
import IntervalChart from '../IntervalChart'
import PriorityChart from '../PriorityChart'
import StatusChart from '../StatusChart'
import BuyersPerformanceSection from '../BuyersPerformanceSection'
import DateIntervalContextProvider from '@/context/DateIntervalContextProvider'
import DateInterval from '@/components/DateInterval'

const PurchaseOrderDashboard = () => {
    return (
        <DateIntervalContextProvider storageKey="purchase-order-interval">
            <div className="p-4 space-y-8">
                <div className="flex items-center justify-between">
                    <div>
                        <TypographyLarge>Dashboard gerencial</TypographyLarge>
                    </div>
                    <DateInterval />
                </div>
                <div className="flex gap-4">
                    <div className="flex-1 space-y-4">
                        <BuyersPerformanceSection />
                        <IntervalChart />
                    </div>
                    <div className="w-[360px] space-y-4">
                        <PriorityChart />
                        <StatusChart />
                    </div>
                </div>
            </div>
        </DateIntervalContextProvider>
    )
}

export default PurchaseOrderDashboard
