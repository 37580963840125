import {
    Section,
    SectionContent,
    SectionHeader,
    SectionTitle,
    SectionValue,
} from '@/components/Section'
import { ScrollArea } from '@/components/ui/scroll-area'
import { TypographyP } from '@/components/ui/typography'
import { useQuotationSuppliersTotals } from '@/hooks/useQuotationSuppliersTotals'
import { currencyFormat, percentageFormat } from '@/utils/stringFormatter'
import { Store } from '@/store/type'
import { useBaseStore } from '@/store'

const stateSelector = (state: Store) => ({
    isSummaryOpen: state.quotationMapSlice.state.isSummaryOpen,
})

const QuotationMapSummary = () => {
    const { suppliers } = useQuotationSuppliersTotals()
    const { isSummaryOpen } = useBaseStore(stateSelector)

    const worstPrice = Math.max(
        ...suppliers.map((supplier) => supplier.totalInitial)
    )
    const bestPrice = Math.min(
        ...suppliers.map((supplier) => supplier.totalFinal)
    )

    return (
        isSummaryOpen && (
            <ScrollArea className="w-[300px] rounded-md p-4 bg-accent">
                <div className="space-y-4">
                    <Section>
                        <SectionHeader>
                            <SectionTitle>Análise valores</SectionTitle>
                        </SectionHeader>
                        <SectionContent>
                            <SectionValue
                                label="Maior valor inicial"
                                className="text-lg font-semibold"
                                value={worstPrice}
                                formatter={currencyFormat}
                            />
                            <SectionValue
                                label="Menor valor final"
                                className="text-lg font-semibold"
                                value={bestPrice}
                                formatter={currencyFormat}
                            />
                        </SectionContent>
                    </Section>
                    <Section>
                        <SectionHeader>
                            <SectionTitle>Fornecedores</SectionTitle>
                        </SectionHeader>
                        <SectionContent className="space-y-4">
                            {suppliers.map((supplier) => {
                                return (
                                    <div
                                        key={supplier.supplierId}
                                        className="p-4 space-y-2 border rounded-md bg-card"
                                    >
                                        <div>
                                            <TypographyP className="text-base font-semibold">
                                                {supplier.supplier}
                                            </TypographyP>
                                        </div>
                                        <SectionValue
                                            label="Valor total inicial"
                                            value={supplier.totalInitial}
                                            formatter={currencyFormat}
                                        />
                                        <SectionValue
                                            className="font-bold"
                                            label="Valor total final"
                                            value={supplier.totalFinal}
                                            formatter={currencyFormat}
                                        />
                                        <SectionValue
                                            label="Total inicial - total final"
                                            value={supplier.totalDifference}
                                            formatter={currencyFormat}
                                        />
                                        <SectionValue
                                            label="Saving baseline"
                                            value={supplier.baselineSaving}
                                            percentage={percentageFormat(
                                                supplier.baselineSavingPercentage *
                                                    100
                                            )}
                                            formatter={currencyFormat}
                                        />
                                        <SectionValue
                                            label="Saving negociação"
                                            value={supplier.negotiationSaving}
                                            percentage={percentageFormat(
                                                supplier.negotiationSavingPercentage *
                                                    100
                                            )}
                                            formatter={currencyFormat}
                                        />
                                        <SectionValue
                                            label="Saving financeiro"
                                            value={supplier.financialSaving}
                                            percentage={percentageFormat(
                                                supplier.financialSavingPercentage *
                                                    100
                                            )}
                                            formatter={currencyFormat}
                                        />
                                    </div>
                                )
                            })}
                        </SectionContent>
                    </Section>
                </div>
            </ScrollArea>
        )
    )
}

export default QuotationMapSummary
