import { TypographyP } from '@/components/ui/typography'
import { cn } from '@/lib/utils'

type DefaultColumnProps = {
    children?: React.ReactNode
    className?: string
}

const DefaultColumn = ({ children, className }: DefaultColumnProps) => {
    return (
        <TypographyP className={cn('truncate text-[11px]', className)}>
            {children}
        </TypographyP>
    )
}

export default DefaultColumn
