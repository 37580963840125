import { Invoice } from '@/api/business/notasFiscais/type'
import Field from './components/Field'
import { currencyFormat, formatCpfOrCnpj } from '@/utils/stringFormatter'
import { ScrollArea } from '@/components/ui/scroll-area'
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns'

type DetailsSectionProps = {
    invoice: Invoice
}

const DetailsSection = ({ invoice }: DetailsSectionProps) => {
    return (
        <ScrollArea className="h-full">
            <div className="px-4 pb-4 space-y-2 max-w-[460px]">
                <Field
                    label="Pedido de compras"
                    value={invoice.NK_PEDIDO_DE_COMPRAS}
                    enableCopyValue
                />
                <Field label="Produto" value={invoice.DS_PRODUTO} />
                <Field
                    label="Unidade fabril"
                    value={invoice.ABREVIATURA_EMPRESA}
                />
                <Field
                    label="Data de emissão"
                    value={format(
                        getDateFromId(Number(invoice.DT_EMISSAO)),
                        'dd/MM/yyyy'
                    )}
                />
                <Field
                    label="Centro de custo"
                    value={invoice.CENTRO_DE_CUSTO || '-'}
                />
                <Field
                    label="Valor unitário"
                    value={currencyFormat(invoice.VL_UNITARIO)}
                />
                <Field
                    label="Valor total"
                    value={currencyFormat(invoice.VL_TOTAL)}
                />
                <Field
                    label="Razão social"
                    value={invoice.DD_RAZAO_SOCIAL || '-'}
                />
                <Field
                    label="Nome fantasia"
                    value={invoice.DD_NOME_FANTASIA || '-'}
                />
                <Field
                    label="CNPJ"
                    value={
                        invoice.DD_CNPJ ? formatCpfOrCnpj(invoice.DD_CNPJ) : '-'
                    }
                />
                <Field
                    label="Observação"
                    value={invoice.DS_OBSERVACAO || '-'}
                />
            </div>
        </ScrollArea>
    )
}

export default DetailsSection
