import { PurchaseOrder } from '@/types/PurchaseOrder'
import Field from './components/Field'

type DetailsSectionProps = {
    purchaseOrder: PurchaseOrder
}

const DetailsSection = ({ purchaseOrder }: DetailsSectionProps) => {
    return (
        <div className="px-4 pb-4 space-y-2 max-w-[460px]">
            <Field label="Produto" value={purchaseOrder.DS_PRODUTO} />
            <Field
                label="Unidade fabril"
                value={purchaseOrder.ABREVIATURA_EMPRESA}
            />
            <Field label="Data de emissão" value={purchaseOrder.DT_EMISSAO} />
            <Field
                label="Centro de custo"
                value={purchaseOrder.CENTRO_DE_CUSTO || '-'}
            />
            <Field label="Prioridade" value={purchaseOrder.DD_URGENCIA} />
            <Field
                label="Aprovador"
                value={purchaseOrder.DS_APROVADOR || '-'}
            />
            <Field
                label="Comprador responsável"
                value={purchaseOrder.DS_NOME_COMPRADOR || '-'}
            />
            <Field
                label="Observação"
                value={purchaseOrder.DS_OBSERVACAO || '-'}
            />
        </div>
    )
}

export default DetailsSection
