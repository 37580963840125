import { PurchaseOrder } from '@/types/PurchaseOrder'
import instance from '..'
import {
    FetchBuyersPerformanceProps,
    FetchIntervalTotalsProps,
    FetchPriorityTotalsProps,
    FetchPurchaseOrderProps,
    FetchStatusTotalsProps,
    IntervalTotals,
    PriorityTotals,
    StatusTotals,
} from './type'
import { PaginationAPIResponse } from '@/types/Pagination'
import { BuyerPerformance } from '../solicitacaoDeCompra/type'
import { getIdFromDate } from '@/utils/date'

const BASE_ROUTE = '/pedidos-de-compras'

export const fetchPurchaseOrder = async ({
    currentPage,
    perPage,
    filters,
}: FetchPurchaseOrderProps) => {
    const response = await instance.post<PaginationAPIResponse<PurchaseOrder>>(
        `${BASE_ROUTE}`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: currentPage,
            FILTROS: filters || [],
        }
    )

    return response.data
}

export const fetchPriorityTotals = async ({
    startDate,
    endDate,
}: FetchPriorityTotalsProps) => {
    const response = await instance.post<PriorityTotals>(
        `${BASE_ROUTE}/total-prioridade`,
        {
            startDate: getIdFromDate(startDate).join(''),
            endDate: getIdFromDate(endDate).join(''),
        }
    )

    return response.data
}

export const fetchIntervalTotals = async ({
    startDate,
    endDate,
}: FetchIntervalTotalsProps) => {
    const response = await instance.post<IntervalTotals>(
        `${BASE_ROUTE}/total-intervalo`,
        {
            startDate: getIdFromDate(startDate).join(''),
            endDate: getIdFromDate(endDate).join(''),
        }
    )

    return response.data
}

export const fetchStatusTotals = async ({
    startDate,
    endDate,
}: FetchStatusTotalsProps) => {
    const response = await instance.post<StatusTotals>(
        `${BASE_ROUTE}/total-status`,
        {
            startDate: getIdFromDate(startDate).join(''),
            endDate: getIdFromDate(endDate).join(''),
        }
    )

    return response.data
}

export const fetchBuyersPerformance = async ({
    startDate,
    endDate,
}: FetchBuyersPerformanceProps) => {
    const response = await instance.post<BuyerPerformance[]>(
        `${BASE_ROUTE}/performance-compradores`,
        {
            startDate: getIdFromDate(startDate).join(''),
            endDate: getIdFromDate(endDate).join(''),
        }
    )

    return response.data
}
