import { Priority } from '@/types/PurchaseOrder'

export default (prioritiy: Priority) => {
    const baseName = '--chart-priority-'

    const variable: Record<Priority, string> = {
        Complemento: `${baseName}complemento`,
        Delegadas: `${baseName}delegadas`,
        Normal: `${baseName}normal`,
        Urgente: `${baseName}urgente`,
        Intercompany: `${baseName}intercompany`,
        Recompra: `${baseName}recompra`,
        Regularização: `${baseName}regularizacao`,
        Indefinido: `${baseName}indefinido`,
    }

    return variable[prioritiy]
}
