import { Skeleton } from '@/components/ui/skeleton'

const LeadTimeSkeleton = () => {
    return (
        <div className="flex-1 h-full space-y-4">
            <div className="flex gap-4">
                <Skeleton className="flex-1 h-[100px]" />
                <Skeleton className="flex-1 h-[100px]" />
                <Skeleton className="flex-1 h-[100px]" />
                <Skeleton className="flex-1 h-[100px]" />
                <Skeleton className="flex-1 h-[100px]" />
            </div>
            <div>
                <Skeleton className="w-full h-[400px]" />
            </div>
        </div>
    )
}

export default LeadTimeSkeleton
