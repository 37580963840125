import { useQuotationMapQuery } from '@/queries/useQuotatioMapQuery'
import { useParams } from 'react-router-dom'
import { useQuotationByIdQuery } from '@/queries/useQuotationQuery'
import QuotationMapDialog from './components/QuotationMapDialog'
import QuotationMapContent from './components/QuotationMapContent'

const QuotationMapSection = () => {
    const { id } = useParams()

    const { data: quotationDetail, isError: isQuotationError } =
        useQuotationByIdQuery({ id: Number(id) })

    const { data: quotationMap, isError: isQuotationMapError } =
        useQuotationMapQuery({ id: Number(id) })

    if (isQuotationError || isQuotationMapError)
        return 'Erro ao renderizar o mapa de cotações'

    if (!quotationDetail || !quotationMap) return 'Carregando...'

    return (
        <>
            <QuotationMapContent />
            <QuotationMapDialog />
        </>
    )
}

export default QuotationMapSection
