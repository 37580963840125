import { TypographyMuted } from '@/components/ui/typography'
import {
    Section,
    SectionContent,
    SectionHeader,
    SectionTitle,
} from '../../../../../../components/Section'
import { useParams } from 'react-router-dom'
import { useApprovalAttachmentsQuery } from '@/queries/useAttachmentsQuery'
import File from '@/components/File'
import { useMutation } from '@tanstack/react-query'
import { downloadFile } from '@/api/business/arquivos'
import { isAxiosError } from '@/api/business'
import { useToast } from '@/components/ui/use-toast'

const AttachmentsSection = () => {
    const { toast } = useToast()

    const { id } = useParams()
    const { data } = useApprovalAttachmentsQuery({ quotationId: Number(id) })
    const { mutate } = useMutation({
        mutationFn: downloadFile,
        onError: (err) => {
            let errorMessage =
                'Não foi possível baixar o arquivo. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao  baixar arquivo',
                description: errorMessage,
            })
        },
    })

    if (!data) return null

    return (
        <Section>
            <SectionHeader>
                <SectionTitle>Anexos</SectionTitle>
            </SectionHeader>
            <SectionContent>
                {data.length === 0 ? (
                    <TypographyMuted>
                        Não há anexos nessa aprovação
                    </TypographyMuted>
                ) : (
                    <div className="overflow-hidden">
                        {data.map((attachment) => (
                            <File
                                key={attachment.CHAVE_DO_ARQUIVO}
                                type="attachment"
                                file={attachment}
                                onDownload={() => {
                                    console.log('first')
                                    mutate({
                                        fileName: attachment.ARQUIVO,
                                        fileKey: attachment.CHAVE_DO_ARQUIVO,
                                        cb: (e) => console.log(e),
                                    })
                                }}
                            />
                        ))}
                    </div>
                )}
            </SectionContent>
        </Section>
    )
}

export default AttachmentsSection
