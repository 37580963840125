import instance from '@/api/business'
import {
    BuyerPerformance,
    FetchLeadTimeProps,
    FetchPurchaseRequestItemsProps,
    FetchPurchaseRequestItemsResponse,
    FetchPurchaseRequestProps,
    FetchPurchaseRequestResponse,
    FetchPurchaseRequestToSelectProps,
    FetchQuotationPurchaseRequestProps,
    FetchQuotationPurchaseRequestResponse,
    IntervalTotals,
    LeadTime,
    PriorityTotals,
    PurchaseRequestFilters,
    PurchaseRequestFlat,
    StatusTotals,
    FetchBuyersPerformanceProps,
    FetchIntervalTotalsProps,
    FetchPriorityTotalsProps,
    FetchStatusTotalsProps,
} from './type'
import { getIdFromDate } from '@/utils/date'

const BASE_ROUTE = '/sc'

export const fetchPurchaseRequest = async ({
    currentPage,
    perPage,
    filters,
}: FetchPurchaseRequestProps) => {
    const response = await instance.post<FetchPurchaseRequestResponse>(
        `${BASE_ROUTE}/paginacao`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: currentPage,
            FILTROS: filters || [],
        }
    )

    return response.data
}

export const fetchQuotationPurchaseRequest = async ({
    currentPage,
    perPage,
    filters,
}: FetchQuotationPurchaseRequestProps) => {
    const response = await instance.post<FetchQuotationPurchaseRequestResponse>(
        `${BASE_ROUTE}/cotacao`,
        {
            POR_PAGINA: perPage,
            PAGINA_ATUAL: currentPage,
            FILTROS: filters || [],
        }
    )

    return response.data
}

export const fetchPurchaseRequestItems = async ({
    companyId,
    purchaseRequestNumber,
}: FetchPurchaseRequestItemsProps) => {
    const response = await instance.post<FetchPurchaseRequestItemsResponse>(
        `${BASE_ROUTE}/itens`,
        {
            SK_EMPRESA: companyId,
            NK_SOLICITACAO_DE_COMPRAS: purchaseRequestNumber,
        }
    )

    return response.data
}

export const fetchPurchaseRequestFilters = async () => {
    const response = await instance.post<PurchaseRequestFilters>(
        `${BASE_ROUTE}/filtros`
    )

    return response.data
}

export const fetchPurchaseRequestToSelect = async ({
    filters,
}: FetchPurchaseRequestToSelectProps) => {
    const response = await instance.post<PurchaseRequestFlat[]>(
        `${BASE_ROUTE}/cotacao/filtros`,
        {
            FILTROS: filters,
        }
    )

    return response.data
}

export const fetchPriorityTotals = async ({
    startDate,
    endDate,
}: FetchPriorityTotalsProps) => {
    const response = await instance.post<PriorityTotals>(
        `${BASE_ROUTE}/total-prioridade`,
        {
            startDate: getIdFromDate(startDate).join(''),
            endDate: getIdFromDate(endDate).join(''),
        }
    )

    return response.data
}

export const fetchIntervalTotals = async ({
    startDate,
    endDate,
}: FetchIntervalTotalsProps) => {
    const response = await instance.post<IntervalTotals>(
        `${BASE_ROUTE}/total-intervalo`,
        {
            startDate: getIdFromDate(startDate).join(''),
            endDate: getIdFromDate(endDate).join(''),
        }
    )

    return response.data
}

export const fetchStatusTotals = async ({
    startDate,
    endDate,
}: FetchStatusTotalsProps) => {
    const response = await instance.post<StatusTotals>(
        `${BASE_ROUTE}/total-status`,
        {
            startDate: getIdFromDate(startDate).join(''),
            endDate: getIdFromDate(endDate).join(''),
        }
    )

    return response.data
}

export const fetchLeadTime = async ({
    purchaseRequestId,
    purchaseOrderId,
}: FetchLeadTimeProps) => {
    const response = await instance.post<LeadTime[]>(`${BASE_ROUTE}/leadtime`, {
        SK_SOLICITACAO_DE_COMPRAS: purchaseRequestId,
        SK_PEDIDO_DE_COMPRAS: purchaseOrderId,
    })

    return response.data
}

export const fetchBuyersPerformance = async ({
    startDate,
    endDate,
}: FetchBuyersPerformanceProps) => {
    const response = await instance.post<BuyerPerformance[]>(
        `${BASE_ROUTE}/performance-compradores`,
        {
            startDate: getIdFromDate(startDate).join(''),
            endDate: getIdFromDate(endDate).join(''),
        }
    )

    return response.data
}
