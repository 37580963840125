import { usePurchaseRequestLeadTimeQuery } from '@/queries/usePurchaseRequestQuery'
import LeadTimeChart from '@/components/LeadTimeChart'
import { getDateFromId } from '@/utils/date'
import { differenceInDays } from 'date-fns'
import LeadTimeCard from './components/LeadTimeCard'
import LeadTimeSkeleton from './components/LeadTimeSkeleton'
import ComponentLoader from '@/components/ComponentLoader'
import { TypographyMuted } from '../ui/typography'

type LeadTimeSectionProps = {
    purchaseRequestId?: string
    purchaseOrderId?: string
}

const LeadTimeDashboard = ({
    purchaseRequestId,
    purchaseOrderId,
}: LeadTimeSectionProps) => {
    const { data = [] } = usePurchaseRequestLeadTimeQuery({
        purchaseRequestId,
        purchaseOrderId,
    })

    if (data.length === 0)
        return (
            <div>
                <TypographyMuted>Leadtime indisponível</TypographyMuted>
            </div>
        )

    const {
        DT_EMISSAO: createdAt,
        DT_APROVACAO: approvedAt,
        DT_EMISSAO_PEDIDO: orderedAt,
        DT_DIGITACAO: deliveredAt,
    } = data[0]

    const approvalDuration = approvedAt
        ? differenceInDays(
              getDateFromId(approvedAt).getTime(),
              getDateFromId(createdAt).getTime()
          )
        : 0

    const orderDuration =
        orderedAt && approvedAt
            ? differenceInDays(
                  getDateFromId(orderedAt).getTime(),
                  getDateFromId(approvedAt).getTime()
              )
            : 0

    const deliveryDuration =
        deliveredAt && orderedAt
            ? differenceInDays(
                  getDateFromId(deliveredAt).getTime(),
                  getDateFromId(orderedAt).getTime()
              )
            : 0

    return (
        <div className="w-full space-y-4">
            <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-2">
                <LeadTimeCard
                    label="Leadtime total"
                    value={approvalDuration + deliveryDuration + orderDuration}
                    valueClassName="text-primary-600"
                />
                <LeadTimeCard
                    label="Leadtime aprovação"
                    value={approvalDuration}
                />
                <LeadTimeCard
                    label="Leadtime aprovação / PC"
                    value={orderDuration}
                />
                <LeadTimeCard
                    label="Leadtime SC / PC"
                    value={approvalDuration + orderDuration}
                />
                <LeadTimeCard
                    label="Leadtime PC / Entrega"
                    value={deliveryDuration}
                />
            </div>
            <LeadTimeChart
                className="w-full"
                approvedAt={approvedAt}
                createdAt={createdAt}
                deliveredAt={deliveredAt}
                orderedAt={orderedAt}
            />
        </div>
    )
}

const LeadTimeSection = ({
    purchaseRequestId,
    purchaseOrderId,
}: LeadTimeSectionProps) => {
    const { isPending, isError, error } = usePurchaseRequestLeadTimeQuery({
        purchaseRequestId,
        purchaseOrderId,
    })

    return (
        <ComponentLoader
            error={error}
            isLoading={isPending}
            isError={isError}
            fallback={<LeadTimeSkeleton />}
        >
            <LeadTimeDashboard
                purchaseOrderId={purchaseOrderId}
                purchaseRequestId={purchaseRequestId}
            />
        </ComponentLoader>
    )
}

export default LeadTimeSection
