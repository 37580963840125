import { Navigate, Outlet, useLocation } from 'react-router-dom'
import LoadingScreen from '@/pages/LoadingScreen'
import { ReactNode, useEffect } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { validateUserToken } from '@/api/auth/user'
import { getItem, setItem } from '@/utils/storage'
import { isAxiosError } from '@/api/business'
import { useBaseStore } from '@/store'
import { Store } from '@/store/type'
import Forbidden from '@/pages/ErrorPages/Forbidden'
import PageLoader from '@/pages/PageLoader'
import Unauthorized from '@/pages/ErrorPages/Unauthorized'

interface RequireAuthProps {
    isAllowed?: boolean
    redirectPath?: string
    children?: ReactNode
}

const stateSelector = (state: Store) => ({
    user: state.authSlice.state.user,
    tokenExpired: state.authSlice.state.tokenExpired,
    login: state.authSlice.actions.login,
    logout: state.authSlice.actions.logout,
})

const RequireAuth = ({
    isAllowed = true,
    redirectPath,
    children,
}: RequireAuthProps) => {
    const { user, tokenExpired, login, logout } = useBaseStore(stateSelector)

    const token = getItem(localStorage, 'token')
    const location = useLocation()

    const queryClient = useQueryClient()

    const { mutate, error, isPending } = useMutation({
        mutationFn: validateUserToken,
        onSuccess: (data) => {
            setItem(localStorage, 'token', token)
            if (data.token) {
                login({
                    token,
                    user: data.token.usuario.nome,
                    userId: data.token.usuario.cod_usuario,
                })
            }
        },
        onError: (err) => {
            console.log(err)
            if (isAxiosError(err)) {
                if (
                    err.response?.status === 401 ||
                    err.response?.status === 402
                ) {
                    logout(queryClient)
                }
            }
        },
    })

    useEffect(() => {
        if (token && !user) {
            mutate()
        }
    }, [token])

    if (!isAllowed) return <Forbidden />

    if (tokenExpired) return <Unauthorized />

    if (!token)
        return (
            <Navigate
                to="/login"
                replace
                state={{ path: redirectPath || location.pathname }}
            />
        )

    return (
        <PageLoader
            fallback={<LoadingScreen />}
            isLoading={isPending}
            error={error}
        >
            {children || <Outlet />}
        </PageLoader>
    )
}

export default RequireAuth
