import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card'
import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from '@/components/ui/chart'
import { useIntervalTotals } from '@/queries/usePurchaseOrderQuery'
import { useMemo } from 'react'
import { Bar, BarChart, LabelList, XAxis, YAxis } from 'recharts'
import getCssVariable from './util/getCssVariable'
import { Intervals } from '@/types/PurchaseOrder'
import { Loader } from 'lucide-react'
import { TypographyMuted, TypographyP } from '@/components/ui/typography'
import { isAxiosError } from '@/api/business'
import { Skeleton } from '@/components/ui/skeleton'
import { useDateIntervalContext } from '@/context/DateIntervalContextProvider'

const IntervalsLabelMapper: Record<Intervals, string> = {
    'ATRASO DE 1 À 5 DIAS': '1 à 5 dias',
    'ATRASO DE 11 À 15 DIAS': '11 à 15 dias',
    'ATRASO DE 16 À 25 DIAS': '16 à 25 dias',
    'ATRASO DE 6 À 10 DIAS': '6 à 10 dias',
    'ATRASO MAIOR QUE 25 DIAS': 'Mais que 25 dias',
    'DENTRO DO PRAZO': 'Dentro do prazo',
    INDEFINIDO: 'Indefinido',
}

const IntervalChartContent = () => {
    const { startDate, endDate } = useDateIntervalContext()

    const {
        data = [],
        isPending,
        isFetching,
        error,
    } = useIntervalTotals({ endDate, startDate })

    const chartData = useMemo(() => {
        return data
            .filter((value) => value.INTERVALO_PRAZO !== 'INDEFINIDO')
            .map((value) => {
                return {
                    INTERVALO_PRAZO: value.INTERVALO_PRAZO.replace(/\s+/g, '-'),
                    TOTAL: Number(value.TOTAL),
                    fill: `var(--color-${value.INTERVALO_PRAZO.replace(
                        /\s+/g,
                        '-'
                    )})`,
                }
            }, [])
    }, [data])

    const chartConfig: ChartConfig = useMemo(() => {
        return data
            .filter((value) => value.INTERVALO_PRAZO !== 'INDEFINIDO')
            .reduce((acc, value) => {
                acc[value.INTERVALO_PRAZO.replace(/\s+/g, '-')] = {
                    label: IntervalsLabelMapper[
                        value.INTERVALO_PRAZO.replace(/"-"+/g, ' ')
                    ],
                    color: `hsl(var(${getCssVariable(value.INTERVALO_PRAZO)}))`,
                }

                return acc
            }, {} satisfies ChartConfig)
    }, [data])

    if (isPending) {
        return (
            <div className="pt-4">
                <Skeleton className="w-full h-[300px]" />
            </div>
        )
    }

    if (error) {
        let errorMessage = 'Erro ao listar atrasos de SC'

        if (isAxiosError(error)) {
            errorMessage = error.response?.data.message || errorMessage
        }

        return (
            <div className="p-4">
                <TypographyP className="text-destructive">
                    {errorMessage}
                </TypographyP>
            </div>
        )
    }

    if (data.length === 0)
        return (
            <div className="p-4">
                <TypographyMuted>Não há dados disponíveis</TypographyMuted>
            </div>
        )

    return (
        <>
            {isFetching && (
                <div className="flex items-center justify-center gap-2 pt-2">
                    <Loader className="animate-spin text-sky-500" size={14} />
                    <TypographyMuted>Atualizando</TypographyMuted>
                </div>
            )}
            <ChartContainer
                config={chartConfig}
                className="w-full p-4 max-h-[250px]"
            >
                <BarChart accessibilityLayer data={Object.values(chartData)}>
                    <ChartTooltip content={<ChartTooltipContent />} />
                    <YAxis
                        dataKey="TOTAL"
                        type="number"
                        domain={[
                            'dataMin',
                            (dataMax: number) => dataMax / 2 + dataMax,
                        ]}
                        hide
                    />
                    <XAxis
                        dataKey="INTERVALO_PRAZO"
                        type="category"
                        tickLine={false}
                        tickMargin={8}
                        axisLine={false}
                        tickFormatter={(value) =>
                            chartConfig[value]?.label as string
                        }
                    />
                    <ChartLegend content={<ChartLegendContent />} />
                    <Bar dataKey="TOTAL" radius={8}>
                        <LabelList
                            position="top"
                            className="fill-foreground"
                            fontSize={11}
                        />
                    </Bar>
                </BarChart>
            </ChartContainer>
        </>
    )
}

const IntervalChart = () => {
    return (
        <Card>
            <CardHeader className="items-center pb-0">
                <CardTitle>Atrasos</CardTitle>
                <CardDescription>Gráfico de atrasos de SCs</CardDescription>
            </CardHeader>
            <CardContent>
                <IntervalChartContent />
            </CardContent>
        </Card>
    )
}

export default IntervalChart
