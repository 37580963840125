import { format, isValid } from 'date-fns'
import { Props } from 'recharts/types/component/Label'

const StartDateLabel = (props: Props) => {
    const { x = 0, y = 0, height = 0, value, fill } = props
    const radius = 8

    const numberX = Number(x)
    const numberY = Number(y)
    const numberHeight = Number(height)

    const date = value && isValid(new Date(value)) ? new Date(value) : null

    const dateString = date ? format(date, 'dd/MM/yyyy') : ''

    return (
        x && (
            <g>
                <circle
                    cx={numberX + numberHeight / 2}
                    cy={numberY + numberHeight / 2}
                    r={radius}
                    fill={fill}
                />
                <text
                    className="fill-muted-foreground"
                    x={x}
                    y={numberY + 24}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={11}
                >
                    {dateString}
                </text>
            </g>
        )
    )
}

export default StartDateLabel
