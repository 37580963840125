import {
    fetchInvoiceById,
    fetchInvoiceByPurchaseOrder,
    fetchInvoicesPaginated,
} from '@/api/business/notasFiscais'
import {
    FetchInvoiceByIdProps,
    FetchInvoiceByPurchaseOrderProps,
    FetchInvoicesPaginatedProps,
} from '@/api/business/notasFiscais/type'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

export const invoiceKeys = {
    all: ['invoice'] as const,
    lists: () => [...invoiceKeys.all, 'list'] as const,
    list: (params: { perPage: number; currentPage: number; filters?: any }) =>
        [...invoiceKeys.lists(), params] as const,
    details: () => [...invoiceKeys.all, 'detail'] as const,
    detail: (id: string) => [...invoiceKeys.details(), id] as const,
    detailPurchaseOrder: (id: string) =>
        [...invoiceKeys.details(), 'purchase-order', id] as const,
}

type UseInvoiceListQueryProps = FetchInvoicesPaginatedProps & {
    enabled: boolean
}

export const useInvoiceListQuery = ({
    currentPage,
    perPage,
    filters,
    enabled,
}: UseInvoiceListQueryProps) => {
    return useQuery({
        queryFn: () =>
            fetchInvoicesPaginated({ currentPage, perPage, filters }),
        queryKey: invoiceKeys.list({
            currentPage,
            perPage,
            filters,
        }),
        placeholderData: keepPreviousData,
        enabled,
    })
}

type UseInvoiceDetailQueryProps = FetchInvoiceByIdProps & {
    enabled: boolean
}

export const useInvoiceDetailQuery = ({
    invoiceId,
    enabled,
}: UseInvoiceDetailQueryProps) => {
    return useQuery({
        queryFn: () => fetchInvoiceById({ invoiceId }),
        queryKey: invoiceKeys.detail(invoiceId),
        placeholderData: keepPreviousData,
        enabled,
    })
}

type UseInvoicePurchaseOrderDetailQueryProps =
    FetchInvoiceByPurchaseOrderProps & {
        enabled: boolean
    }

export const useInvoicePurchaseOrderDetailQuery = ({
    purchaseOrderId,
    enabled,
}: UseInvoicePurchaseOrderDetailQueryProps) => {
    return useQuery({
        queryFn: () => fetchInvoiceByPurchaseOrder({ purchaseOrderId }),
        queryKey: invoiceKeys.detailPurchaseOrder(purchaseOrderId),
        placeholderData: keepPreviousData,
        enabled,
    })
}
