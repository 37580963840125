import { PurchaseOrderStatus } from '@/types/PurchaseOrder'

export default (prioritiy: PurchaseOrderStatus) => {
    const baseName = '--chart-status-'

    const variable: Record<PurchaseOrderStatus, string> = {
        INDEFINIDO: `${baseName}indefinido`,
        'EM APROVAÇÃO': `${baseName}em-aprovacao`,
        'EM RECEBIMENTO': `${baseName}em-recebimento`,
        'PARCIALMENTE ATENDIDO': `${baseName}parcialmente-atendido`,
        'PC ELIMINADO POR RESÍDUO': `${baseName}pc-eliminado-por-residuo`,
        'PEDIDO SALVO': `${baseName}pedido-salvo`,
        'REJEITADO PELO APROVADOR': `${baseName}rejeitado`,
        RECEBIDO: `${baseName}recebido`,
        PENDENTE: `${baseName}pendente`,
    }

    return variable[prioritiy]
}
